/* eslint-disable no-case-declarations,prefer-promise-reject-errors */
import axios from 'axios';
import axiosRetry from 'axios-retry';
import parse from 'date-fns/parse';
import he from 'he';

import { ABBR_DOMAIN } from '../constants';

import config from './config';

import * as Sentry from '@sentry/browser';

class Adapter {
  constructor() {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    this.axiosInstance = axios.create({
      timeout: 5000,
      withCredentials: true,
      timeoutErrorMessage: 'CRITICAL_REQUEST_TIMEOUT',
      cancelToken: source.token,
      validateStatus: (status) => Number(status) === 200,
    });

    axiosRetry(this.axiosInstance, {
      retryDelay: axiosRetry.exponentialDelay,
      retries: 3,
    });

    this.cancelToken = source.token;

    return this;
  }

  cancel() {
    return this.cancelToken.cancel();
  }

  getUser() {
    return this.axiosInstance.get(`${ config.url.main }users/current`, {
      params: {
        expand: true
      }
    })
      .then(this.normalizeResponseData('get_user'))
      // eslint-disable-next-line prefer-promise-reject-errors
      .catch(this.handleError);
  }

  logoutUser() {
    return this.axiosInstance.post(`${ config.url.main }users/logout`)
      .then(() => {
        return new Promise((resolve) => {
          window.location = `${ config.url.auth }auth/realms/Abbr/protocol/openid-connect/logout?redirect_uri=${ window.location.origin }`;

          setTimeout(resolve, 3000);
        });
      })
      .catch(this.handleError);
  }

  updateUser({
    id,
    ...props
  }) {
    let updatedProps = Object.entries(props).map(([ key, value ]) => ({
      op: 'add',
      path: `/${ key }`,
      value,
    }));

    const notificationSettings = updatedProps.find((item) => item.path === '/notifications');

    if (notificationSettings) {
      Object.keys(notificationSettings.value).forEach((key) => {
        updatedProps.push({
          op: 'add',
          path: `/notifications/${ key }/enabled`,
          value: notificationSettings.value[key],
        });
      });

      updatedProps = updatedProps.filter((item) => item.path !== '/notifications');
    }

    return this.axiosInstance.patch(`${ config.url.main }${ id }`, updatedProps)
      .then(this.normalizeResponseData())
      .catch(this.handleError);
  }

  getEventsForUser({
    limit = 20,
    offset = 0,
    handled = false,
  } = {}) {
    return this.axiosInstance.get(`${ config.url.main }events`, {
      params: {
        limit,
        offset,
        handled,
      },
    })
      .then(this.normalizeResponseData('get_events_for_user'))
      .catch(this.handleError);
  }

  handleUserEvent(id) {
    const patch = [
      {
        op: 'add',
        path: '/handled',
        value: true,
      },
    ];

    return this.axiosInstance.patch(`${ config.url.main }${ id }`, patch)
      .then(this.normalizeResponseData('handle_user_event'))
      .catch(this.handleError);
  }

  deleteUser() {
    return this.axiosInstance.delete(`${ config.url.main }users/current`)
      .then(this.normalizeResponseData())
      // eslint-disable-next-line prefer-promise-reject-errors
      .catch(this.handleError);
  }

  getClickCount(id) {
    return this.axiosInstance.post(`${ config.url.main }abbrs/${ id }/reports/issue/click_count`, {})
      .then(this.normalizeResponseData('get_click_count'))
      // eslint-disable-next-line prefer-promise-reject-errors
      .catch(this.handleError);
  }

  fetchAbbrs({
    limit,
    offset,
  }, { token }) {
    return this.axiosInstance.get(`${ config.url.main }abbrs`, {
      headers: { 'X-AnonSession': token },
      params: {
        limit,
        offset,
      },
    })
      .then(this.normalizeResponseData('fetch_abbrs'))
      .catch(this.handleError);
  }

  getTotalCount(ids) {
    return this.axiosInstance.post(`${ config.url.main }reports/issue/total_count`, {
      ids,
    })
      .then((res) => {
        if (res.data.status === 'fail') return res.data.result;

        const views = res.data.result;

        return Object.keys(res.data.result).map((id) => {
          return {
            id,
            metrics: {
              views: {
                total: views[id],
              },
            },
          };
        });
      })
      .catch(this.handleError);
  }

  getAbbr(id) {
    return this.axiosInstance.get(`${ config.url.main }${ id }`)
      .then(this.normalizeResponseData('get_abbr'))
      .catch(this.handleError);
  }

  updateAbbr({
    id,
    ...props
  }) {
    const normalizedData = this.normalizeRequestData(props);

    const updatedProps = Object.entries(normalizedData).map(([ key, value ]) => ({
      op: 'add',
      path: `/${ key }`,
      value,
    }));

    return this.axiosInstance.patch(`${ config.url.main }${ id }`, updatedProps)
      .then(this.normalizeResponseData('get_abbr'))
      .catch(this.handleError);
  }

  createAbbr(data, { token }) {
    const normalizedData = this.normalizeRequestData(data);

    return this.axiosInstance.post(`${ config.url.main }abbrs`, { ...normalizedData }, {
      headers: { 'X-AnonSession': token },
    })
      .then((res) => {
        if (res.data.status === 'ok') {
          return Promise.resolve(res);
        }

        return Promise.reject(res);
      })
      .then(this.normalizeResponseData('create_abbr'))
      .catch(this.handleError);
  }

  deleteAbbr(id) {
    return this.axiosInstance.delete(`${ config.url.main }${ id }`)
      .then((res) => res.data)
      .catch(this.handleError);
  }

  fetchSlashtag({ token }) {
    return this.axiosInstance.get(`${ config.url.main }abbrs/fresh`, {
      headers: { 'X-AnonSession': token },
    })
      .then(this.normalizeResponseData('fetch_slashtag'))
      .catch(this.handleError);
  }

  checkSlashtag(slashtag, { token }) {
    return this.axiosInstance.get(`${ config.url.main }abbrs/check/${ slashtag }`, {
      headers: { 'X-AnonSession': token },
    })
      .then(this.normalizeResponseData('check_slashtag'))
      .catch(this.handleError);
  }

  fetchPreview(url) {
    return this.axiosInstance.get(`${ config.url.preview }preview`, {
      withCredentials: false,
      params: { url },
    })
      .then((res) => {
        if (res.data.ok) {
          return this.normalizeResponseData('fetch_preview')(res);
        }

        return Promise.reject();

      })
      .catch(this.handleError);
  }

  normalizeRequestData = (data) => {
    const {
      slashtag: abbr,
      image,
      ...otherParameters
    } = data;

    const payload = {
      ...otherParameters,
      ...(abbr ? { abbr } : {}),
    };

    if (image) {
      Object.assign(payload, {
        params: {
          image: {
            url: image,
          },
        },
      });
    }

    return payload;
  };

  normalizeResponseData = (code) => (res) => {
    switch (code) {
      case 'get_click_count':
        return res.data.result.map(([ date, value ]) => ({
          date: parse(date, 'yyyyMMdd', new Date()).getTime(),
          value,
        }));
      case 'get_user':
        const userData = res.data.result;

        return ({
          isLoggedIn: true,
          visitedFirstTime: false,
          name: userData.name.startsWith('#') ? '' : userData.name,
          email: userData.email,
          id: userData.id,
        });
      case 'get_events_for_user':
        const events = {};

        res.data.result.forEach((e) => {
          events[e.id] = e;
        });

        return events;
      case 'handle_user_event':
        const event = res.data.result;

        return { [event.id]: event };
      case 'get_abbr':
        const abbrData = res.data.result;

        return ({
          id: abbrData.id,
          type: abbrData.type,
          status: abbrData.status,
          name: abbrData.name,
          url: abbrData.url,
          slashtag: abbrData.abbr,
          tracking: abbrData.tracking,
          customize: abbrData.customize,
        });
      case 'fetch_abbrs':
        return res.data.result.map(({
          abbr,
          name,
          url,
          type,
          status,
          id,
          tracking,
          customize,
        }) => ({
          slashtag: abbr,
          url,
          type,
          status,
          id,
          name,
          tracking,
          customize,
        }));
      case 'create_abbr':
        const {
          id,
          abbr: slashtag,
        } = res.data.result;

        return {
          id,
          slashtag,
        };
      case 'check_slashtag':
        // eslint-disable-next-line prefer-promise-reject-errors
        return res.data.result ? Promise.resolve() : Promise.reject({ response: { data: 'SLASHTAG_TAKEN' } });
      case 'fetch_preview':
        const {
          site_name: siteName,
          title,
          description,
          image,
        } = res.data;

        return {
          siteName,
          title: title ? he.decode(title) : undefined,
          description: description ? he.decode(description) : undefined,
          image,
        };
      case 'fetch_slashtag':
        return {
          domain: ABBR_DOMAIN,
          slashtag: res.data.result,
        };
      default:
        return res.data.result || res.data;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  handleError(res) {
    Sentry.captureException(res);

    console.log('error: ', res);

    let code;

    if (res.response?.status === 401) {
      return Promise.reject({
        type: 'UNAUTHORIZED',
      });
    }

    if (res.data?.alerts?.length > 0 || typeof res.response?.data === 'string') {
      const error = res.data.alerts ? res.data.alerts[0] : { message: res.response.data };

      switch (error.message) {
        case 'INTERNAL_ERROR':
          code = 'CRITICAL_SERVER_ERROR';

          break;
        case 'MISSING_MANDATORY_PARAMETER':
          code = 'CRITICAL_NO_REQUIRED_PARAMETER';

          break;
        case 'Not Found':
        case 'INVALID_PARAMETER':
          code = 'CRITICAL_INVALID_PARAMETER';

          break;
        case 'DUPLICATE_ABBR':
          code = 'CRITICAL_DUPLICATE_ABBR';

          break;
        case 'INVALID_ABBR':
          code = 'CRITICAL_INVALID_ABBR';

          break;

        case 'SLASHTAG_TAKEN':
          code = 'SLASHTAG_TAKEN';

          break;

        case 'POTENTIALLY_MALICIOUS_URL':
          code = 'POTENTIALLY_MALICIOUS_URL';

          break;

        default:
          if (error.message.indexOf('Illegal character') >= 0) {
            code = 'CRITICAL_INVALID_PARAMETER';
          } else if (error.message.indexOf('Server returned status: 404') >= 0) {
            code = 'PREVIEW_FETCHING_FAILED';
          } else {
            code = 'CRITICAL_UNKNOWN_ERROR';
          }
      }
    } else {
      switch (res.status) {
        case 500:
          code = 'CRITICAL_SERVER_ERROR';

          break;
        case 400:
          code = 'CRITICAL_BAD_REQUEST';

          break;
        default:
          code = 'CRITICAL_NETWORK_ERROR';
      }
    }

    return Promise.reject({
      type: code,
      error: res.message || ({ ...res }),
    });
  }
}

export default Adapter;
