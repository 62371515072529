import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';

import AbbrIcon from 'components/AbbrIcon';
import CopyButton from 'components/CopyButton';
import DeleteDialog from 'components/DeleteDialog';
import Icon from 'components/Icon';
import IconButton from 'components/IconButton';
import { useLoader } from 'components/Loader';
import ShareButton from 'components/ShareButton';

import { getUser } from 'store/reducers/user';
import { useMedia } from 'shared/helpers/hocs/withMedia';

import styles from './ListItem.module.scss';

const wrapperVariants = {
  copying: {
    x: '2.5rem',
  },
  interacted: {
    x: '-7.5rem',
  },
};

const actionsVariants = {
  hidden: {
    opacity: 0,
    x: 0,
  },
  visible: (multiplier) => ({
    opacity: 1,
    x: `-${ multiplier * 2.5 }rem`,
  }),
  visibleLeft: {
    opacity: 1,
    x: '2.5rem',
  },
};

const ListItem = ({
  to,
  id,
  name,
  type,
  domain,
  slashtag,
  preview,
  metrics,
  interacted,
  onInteract,
}) => {
  const copiedTimeout = useRef(0);
  const hoverTimeout = useRef(0);
  const [ copying, setCopying ] = useState(false);
  const [ interactionCaptured, setInteractionCaptured ] = useState(false);
  const [ isLoading ] = useLoader();
  const {
    isDesktop,
  } = useMedia();

  const { isLoggedIn } = useSelector(getUser);

  const handleInteract = (event, info) => {
    const { delta: { x } } = info;

    if (x > 5 && interacted) {
      onInteract(null);

      return;
    }

    if (x > 5) {
      setCopying(true);

      return;
    }

    if (x < -5 && copying) {
      setCopying(false);

      return;
    }

    if (x < -5) {
      onInteract(id);
    }
  };

  const handleHoverStart = () => {
    hoverTimeout.current = setTimeout(() => {
      onInteract(id);
    }, 200);
  };

  const handleHoverEnd = () => {
    clearTimeout(hoverTimeout.current);
    onInteract(null);
  };

  const handleCloseActions = (e) => {
    if (e.target.classList.contains(styles.wrapper)) {
      onInteract(null);
    }
  };

  const handleModalOpen = (openModal) => () => {
    isDesktop && setInteractionCaptured(true);
    openModal();
  };

  const handleModalClose = () => {
    isDesktop && setInteractionCaptured(false);
    handleHoverEnd();
  };

  const handleCopied = () => {
    clearTimeout(copiedTimeout.current);

    copiedTimeout.current = setTimeout(() => {
      setCopying(false);
    }, 1000);
  };

  useEffect(() => {
    return () => {
      clearTimeout(copiedTimeout.current);
      clearTimeout(hoverTimeout.current);
    };
  }, []);

  return (
    <motion.div
      onPanStart={ !isDesktop ? handleInteract : undefined }
      onClick={ !isDesktop && interacted ? handleCloseActions : undefined }
      className={ styles[interacted ? 'wrapperInteracted' : 'wrapper'] }
    >
      <Link
        to={ to }
        className={ styles.link }
      >
        <motion.div
          className={ styles.inner }
          variants={ wrapperVariants }
          animate={ copying ? 'copying' : '' }
        >
          <div className={ styles.type }>
            <AbbrIcon
              type={ type }
              size="20"
            />
          </div>
          <div className={ styles.content }>
            { name && <p className={ styles.caption }>{ name }</p> }

            <div className={ styles.abbr }>
              <p className={ styles.title }>{ `${ domain }/${ slashtag }` }</p>

              {
                isLoggedIn && (
                  <div className={ styles.views }>
                    <Icon
                      icon="eye"
                      size="12"
                    />
                    <span className={ styles.viewsLabel }>{ metrics.views.total }</span>
                  </div>
                )
              }
            </div>
          </div>
        </motion.div>
      </Link>
      {
        !isDesktop && (
          <AnimatePresence initial={ false }>
            {
              copying && (
                <motion.div
                  key="copy"
                  initial="hidden"
                  animate="visibleLeft"
                  exit="hidden"
                  variants={ actionsVariants }
                  className={ styles.copying }
                >
                  <CopyButton
                    variant="ghost"
                    permalink={ `https://${ domain }/${ slashtag }` }
                    onCopied={ handleCopied }
                    data-location="abbr_list"
                    small
                    fill
                  />
                </motion.div>
              )
            }
          </AnimatePresence>
        )
      }

      <motion.div
        className={ styles.hoverZone }
        onHoverStart={ isDesktop ? handleHoverStart : undefined }
        onHoverEnd={ isDesktop && !interactionCaptured ? handleHoverEnd : undefined }
      >
        { isDesktop && <Icon icon="more-vertical" /> }

        <AnimatePresence initial={ false }>
          {
            interacted && (
              <motion.div
                initial="hidden"
                animate="visible"
                exit="hidden"
                key="actions"
                variants={ actionsVariants }
                transition={
                  {
                    staggerChildren: 0.05,
                    staggerDirection: -1,
                    duration: 0.1,
                  }
                }
                className={ styles.actions }
              >

                <motion.div
                  variants={ actionsVariants }
                  className={ styles.actionsItem }
                  custom={ isDesktop ? 3 : 1 }
                >
                  <DeleteDialog
                    id={ id }
                    abbr={ `${ domain }/${ slashtag }` }
                    onModalClose={ handleModalClose }
                    location="abbr_list"
                  >
                    {
                      ({ openModal }) => (
                        <IconButton
                          variant="ghost"
                          icon="trash"
                          disabled={ isLoading }
                          onClick={ handleModalOpen(openModal) }
                          fill
                        />
                      )
                    }
                  </DeleteDialog>
                </motion.div>
                <motion.div
                  variants={ actionsVariants }
                  className={ styles.actionsItem }
                  custom={ 2 }
                >
                  <IconButton
                    variant="ghost"
                    icon="external"
                    href={ `https://${ domain }/${ slashtag }` }
                    target="_blank"
                    data-location="abbr_list"
                    data-abbr="check"
                    fill
                  />
                </motion.div>
                <motion.div
                  variants={ actionsVariants }
                  className={ styles.actionsItem }
                  custom={ isDesktop ? 1 : 3 }
                >
                  {
                    !isDesktop ? (
                      <ShareButton
                        variant="ghost"
                        title={ preview?.title }
                        text={ preview?.description }
                        url={ `https://${ domain }/${ slashtag }` }
                        data-location="abbr_list"
                        small
                        fill
                      />
                    ) : (
                      <CopyButton
                        variant="ghost"
                        permalink={ `https://${ domain }/${ slashtag }` }
                        onCopied={ handleCopied }
                        data-location="abbr_list"
                        small
                        fill
                      />
                    )
                  }
                </motion.div>
              </motion.div>
            )
          }
        </AnimatePresence>
      </motion.div>
    </motion.div>
  )
  ;
};

ListItem.propTypes = {
  interacted: PropTypes.bool.isRequired,
  to: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
  domain: PropTypes.string.isRequired,
  slashtag: PropTypes.string.isRequired,
  preview: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
  metrics: PropTypes.shape({
    views: PropTypes.shape({
      total: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  onInteract: PropTypes.func.isRequired,
};

ListItem.defaultProps = {
  name: '',
};

export default ListItem;
