import React from 'react';
import { motion } from 'framer-motion';

import Menu from 'components/Menu';
import Modal, { useModal, withModal } from 'components/Modal';

import Icon from '../Icon';

import styles from './Navigation.module.scss';

const Navigation = () => {
  const {
    isOpen,
    openModal,
    closeModal,
  } = useModal();

  return (
    <>
      <motion.button
        className={ styles.toggle }
        type="button"
        onClick={ isOpen ? closeModal : openModal }
      >
        <Icon
          icon={ isOpen ? 'cross' : 'menu' }
          size="20"
        />
      </motion.button>

      <Modal
        className={ styles.menu }
        animation="fade"
        position="center-center"
        label="Navigation"
      >
        <Menu />
      </Modal>
    </>
  );
};

export default withModal(Navigation);
