import React, { useCallback, useEffect, useRef, useState } from 'react';
import IMask from 'imask';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import IconButton from 'components/IconButton';
import Input from 'components/Input';
import { useLoader } from 'components/Loader';
import { Caption } from 'components/Typography';

import useAction from 'shared/helpers/hooks/useAction';
import useClipboard from 'shared/helpers/hooks/useClipboard';

import styles from './PixelManager.module.scss';

const facebookMask = IMask.createMask({
  mask: '000000000000000[0]',
});

const googleMask = IMask.createMask({
  mask: [
    {
      mask: 'UA-`000000000-`0',
    },
    {
      mask: 'G-`**********',
      prepare: (value) => value.toUpperCase(),
      definitions: {
        '*': /[a-zA-Z0-9]/,
      },
    },
  ],
  dispatch: (appended, dynamicMasked) => {
    const value = dynamicMasked.value + appended;

    if (value.match(/^[^U]/)) {
      return dynamicMasked.compiledMasks[1];
    }

    return dynamicMasked.compiledMasks[0];
  },
});

const PixelManager = ({
  tracking,
  onChange,
}) => {
  const facebookRef = useRef();
  const facebookMaskRef = useRef(facebookMask);

  const googleRef = useRef();
  const googleMaskRef = useRef(googleMask);

  const pasteUsed = useRef(false);

  const [ pixels, setPixels ] = useState({
    facebook: facebookMaskRef.current.resolve(tracking.facebook),
    google: googleMaskRef.current.resolve(tracking.google),
  });
  const [ saveAllowed, setSaveAllowed ] = useState(false);
  const [ isLoading, setLoading ] = useLoader();
  const { trackEvent } = useAction();

  const {
    supportsClipboard,
    getClipboard,
  } = useClipboard();

  const googlePixelTouched = tracking.google !== pixels.google;
  const googlePixelValid = pixels.google.startsWith('G-') && pixels.google.length === 12 || pixels.google.startsWith('UA-') && pixels.google.length === 14 || pixels.google.length === 0;

  const facebookPixelTouched = pixels.facebook !== tracking.facebook;
  const facebookPixelValid = pixels.facebook.length === 15 || pixels.facebook.length === 16 || pixels.facebook.length === 0;

  const handlePixelChange = useCallback((key) => (val) => {
    let value = typeof val === 'string' ? val : val.target.value;

    if (key === 'google') {
      value = googleMaskRef.current.resolve(value);
    } else {
      value = facebookMaskRef.current.resolve(value);
    }

    setPixels({
      ...pixels,
      [key]: value,
    });
  }, [ pixels, setPixels ]);

  const handlePaste = (key) => (event) => {
    pasteUsed.current = true;
    trackEvent('ABBR/PIXEL/PASTE');

    if (event.type === 'paste') {
      handlePixelChange(key)(event);

      return;
    }

    getClipboard()
      .then((value) => {

        if (key === 'facebook') {
          handlePixelChange('facebook')(value);
          facebookRef.current.focus();
        }

        if (key === 'google') {
          handlePixelChange('google')(value);
          googleRef.current.focus();
        }
      })
      .catch(() => {
      });
  };

  const handleSave = () => {
    const addedPixels = [];

    setLoading(true);

    if (!tracking.google && pixels.google) {
      addedPixels.push('google');
    }

    if (!tracking.facebook && pixels.facebook) {
      addedPixels.push('facebook');
    }

    onChange(pixels)
      .then(() => {
        if (addedPixels.length > 0) {
          trackEvent('ABBR/PIXEL', {
            pixels: addedPixels,
          });

          if (!pasteUsed.current) {
            trackEvent('ABBR/PIXEL/MANUAL_INPUT');
          }
        }

      })
      .catch(() => {
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (
      (!googlePixelTouched || googlePixelTouched && googlePixelValid)
      && (!facebookPixelTouched || facebookPixelTouched && facebookPixelValid)
    ) {
      setSaveAllowed(true);
    } else {
      setSaveAllowed(false);
    }
  }, [ googlePixelValid, googlePixelTouched, pixels ]);

  const facebookHint = (
    <div
      className={ styles.hint }
      data-pixels-hint="facebook"
    >
      <Caption marginBottom="4">
        ID of your Facebook Pixel that you can find in Events Manager dashboard.
        <br />
        It is 15-16 digits length number.
      </Caption>

      <Caption marginBottom="4">
        Example:
        <br />
        123456789101234
      </Caption>

      <a
        href="https://www.facebook.com/business/help/952192354843755"
        target="_blank"
        rel="noopener noreferrer"
        data-pixels-docs="facebook"
      >
        Learn more
      </a>
    </div>
  );

  const googleHint = (
    <div
      className={ styles.hint }
      data-pixels-hint="google"
    >
      <Caption marginBottom="4">
        Tracking ID if you use Universal Analytics or Measurement ID if you use Google Analytics 4.
      </Caption>

      <Caption marginBottom="4">
        Example:
        <br />
        G-6XM95P082R
        <br />
        UA-166327049-2
      </Caption>

      <a
        href="https://support.google.com/analytics/answer/9306384"
        target="_blank"
        rel="noopener noreferrer"
        data-pixels-docs="google"
      >
        Learn more
      </a>
    </div>
  );

  return (
    <div className={ styles.manager } data-pixels="visible">
      <div className={ styles.group }>
        <Input
          inputRef={ facebookRef }
          label="Facebook Pixel ID"
          value={ pixels.facebook }
          onChange={ handlePixelChange('facebook') }
          onPaste={ handlePaste('facebook') }
          error={ facebookPixelTouched && !facebookPixelValid }
          hint={ facebookHint }
          endAdornment={
            supportsClipboard && (
              <IconButton
                variant="ghost"
                icon="paste"
                small
                onClick={ debounce(handlePaste('facebook'), 200) }
              />
            )
          }
        />
      </div>

      <div className={ styles.group }>
        <Input
          inputRef={ googleRef }
          label="Google Analytics ID"
          value={ pixels.google }
          onChange={ handlePixelChange('google') }
          onPaste={ handlePaste('google') }
          error={ googlePixelTouched && !googlePixelValid }
          hint={ googleHint }
          endAdornment={
            supportsClipboard && (
              <IconButton
                variant="ghost"
                icon="paste"
                small
                onClick={ debounce(handlePaste('google'), 200) }
              />
            )
          }
        />
      </div>

      <div className={ styles.group }>
        <Button
          onClick={ handleSave }
          disabled={ !saveAllowed || isLoading }
          block
        >
          Save
        </Button>
      </div>
    </div>
  );
};

PixelManager.propTypes = {
  tracking: PropTypes.shape({
    google: PropTypes.string,
    facebook: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PixelManager;
