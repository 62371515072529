/* eslint-disable jsx-a11y/no-noninteractive-tabindex,no-param-reassign */
import React, { useLayoutEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';

import useDoubleClick from 'shared/helpers/hooks/useDoubleClick';

import styles from './Editable.module.scss';

const Editable = ({
  inputRef,
  tooltip,
  adornment,
  onChange,
  onBlur,
  onKeyPress,
  value,
  error,
  placeholder,
  full,
}) => {
  const localRef = useRef();
  const bufferRef = useRef();
  const reactTooltipRef = useRef(null);

  const [ _value, setValue ] = useState(value);

  const InputComponent = full ? 'textarea' : 'input';

  const tooltipId = useRef(uniqueId());

  const restrictSymbols = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleClick = useDoubleClick({
    onDoubleClick: (e) => e.target.select(),
  });

  const handleKeyPress = (e) => {
    restrictSymbols(e);

    typeof onKeyPress === 'function' && onKeyPress(e);
  };

  const handleAutosize = () => {
    if (!bufferRef.current) return;

    const width = `${ bufferRef.current.clientWidth }px`;
    const height = `${ bufferRef.current.clientHeight - 8 }px`;

    if (inputRef?.current) {
      inputRef.current.style.width = width;
    } else if (localRef.current) {
      localRef.current.style.width = width;
    }

    if (!full) return;

    if (inputRef?.current) {
      inputRef.current.style.height = height;
    } else {
      localRef.current.style.height = height;
    }
  };

  const handleChange = (e) => {
    const newValue = e.target.value;

    setValue(newValue);
    onChange(newValue);

    bufferRef.current.value = newValue;

    setTimeout(handleAutosize, 0);
  };

  const handleBlur = () => {
    if (typeof onBlur === 'function') {
      onBlur(_value);
    }
  };

  useLayoutEffect(() => {
    setValue(value);

    setTimeout(() => {
      handleAutosize();
    }, 0);
  }, [ value ]);

  return (
    <div className={ styles[error ? 'error' : 'editable'] }>
      <InputComponent
        ref={ inputRef || localRef }
        className={ styles[full ? 'textarea' : 'input'] }
        value={ _value }
        placeholder={ !_value && placeholder ? placeholder : undefined }
        onChange={ handleChange }
        onKeyPress={ handleKeyPress }
        onBlur={ handleBlur }
        onClick={ handleClick }
        spellCheck={ false }
        rows={ 1 }
        data-tip
        data-for={ tooltipId.current }
        data-event="focus"
        data-event-off="blur"
      />
      {
        adornment && (
          <div
            className={ styles.adornment }
          >
            <div className={ styles.adornmentInner }>
              { adornment }
            </div>
          </div>
        )
      }
      <span
        ref={ bufferRef }
        className={ styles.buffer }
      >
        { _value || placeholder }
      </span>
      {
        tooltip && (
          <ReactTooltip
            ref={ reactTooltipRef }
            className={ styles.tooltip }
            id={ tooltipId.current }
            place="bottom"
            effect="solid"
            afterShow={
              () => {
                const { tooltipRef } = reactTooltipRef.current;

                if (!tooltipRef) return;

                const rect = tooltipRef.getBoundingClientRect();

                const overflownLeft = rect.left < 0;
                const overflownRight = rect.right > window.innerWidth;

                if (overflownLeft) {
                  tooltipRef.style.setProperty('left', '10px');
                  tooltipRef.style.setProperty('right', 'auto');
                } else if (overflownRight) {
                  tooltipRef.style.setProperty('left', 'auto');
                  tooltipRef.style.setProperty('right', '10px');
                }
              }
            }
          >
            { tooltip }
          </ReactTooltip>
        )
      }
    </div>
  );
};

Editable.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  full: PropTypes.bool,
  tooltip: PropTypes.node,
  adornment: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
};

Editable.defaultProps = {
  inputRef: null,
  placeholder: '',
  full: false,
  onKeyPress: undefined,
  onBlur: undefined,
  tooltip: null,
  adornment: null,
  error: false,
};

export default Editable;
