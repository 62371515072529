import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from 'components/Button';
import Carousel from 'components/Carousel';
import Container from 'components/Container';
import { Heading, Paragraph } from 'components/Typography';

import { haveAbbrs } from 'store/reducers/abbrs';
import { getUser } from 'store/reducers/user';
import { useMedia } from 'shared/helpers/hocs/withMedia';
import useAction from 'shared/helpers/hooks/useAction';
import useFirstMount from 'shared/helpers/hooks/useFirstMount';

import desktopScreen from './desktop.jpg';
import firstScreen from './first.png';
import styles from './Onboarding.module.scss';
import secondScreen from './second.png';
import thirdScreen from './third.png';

const Onboarding = () => {
  const [ initialized, setInitialized ] = useState(false);
  const user = useSelector(getUser);
  const isHaveAbbrs = useSelector(haveAbbrs);
  const history = useHistory();
  const {
    registerFirstVisit,
  } = useAction();
  const {
    isDesktop,
  } = useMedia();

  useFirstMount(() => {
    if (!user.visitedFirstTime) {
      history.push(user.isLoggedIn && isHaveAbbrs ? '/abbrs' : '/create');
    } else {
      setInitialized(true);
    }
  }, []);

  const startJourney = () => {
    registerFirstVisit();
    history.push(user.isLoggedIn && isHaveAbbrs ? '/abbrs' : '/create');
  };

  if (!initialized) return null;

  return (
    <div className={ styles.page }>
      {
        !isDesktop && (
          <Carousel
            fixedChildren={
              (
                <Button onClick={ startJourney }>
                  Start for free
                </Button>
              )
            }
          >
            <div className={ styles.slide }>
              <img
                className={ styles.slidePicture }
                src={ firstScreen }
                alt="Abbr creating screen"
              />
              <p className={ styles.slideLabel }>Easy to create</p>
            </div>
            <div className={ styles.slide }>
              <img
                className={ styles.slidePicture }
                src={ secondScreen }
                alt="Abbr customization screen"
              />
              <p className={ styles.slideLabel }>Easy to customize</p>
            </div>
            <div className={ styles.slide }>
              <img
                className={ styles.slidePicture }
                src={ thirdScreen }
                alt="Abbrs list screen "
              />
              <p className={ styles.slideLabel }>Easy to manage</p>
            </div>
          </Carousel>
        )
      }

      {
        isDesktop && (
          <Container
            className={ styles.content }
            narrow
          >
            <Heading
              marginBottom="24"
              align="center"
              type="h1"
            >
              Enhance your links with Abbr.
            </Heading>

            <div className={ styles.picture }>
              <img
                src={ desktopScreen }
                alt="Abbr Studio"
              />
            </div>

            <Paragraph
              className={ styles.subtitle }
              align="center"
              marginTop="32"
              marginBottom="32"
            >
              Easy to create • Easy to customize • Easy to manage
            </Paragraph>

            <div className={ styles.button }>
              <Button
                block
                onClick={ startJourney }
              >
                Start for free
              </Button>
            </div>
          </Container>
        )
      }
    </div>
  );
};

export default Onboarding;
