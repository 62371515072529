import React from 'react';
import { toDate } from 'date-fns';
import PropTypes from 'prop-types';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

import styles from './Chart.module.scss';

const dummyData = [
  {
    date: Date.now(),
    value: 0,
  },
];

const CustomizedXAxisTick = ({
  x,
  y,
  payload,
}) => {
  const value = toDate(payload.value).toLocaleString('en-US', {
    day: 'numeric',
    month: 'short',
  });

  return (
    <text
      x={ x }
      y={ y }
      dx={ 15 }
      dy={ 15 }
      textAnchor="end"
      fill="#fff"
      className={ styles.label }
    >
      { value }
    </text>
  );
};

CustomizedXAxisTick.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  payload: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
};

const CustomizedYAxisTick = ({
  x,
  y,
  payload,
}) => {
  return (
    <text
      x={ x + 2 }
      y={ y }
      dy={ 0 }
      textAnchor="end"
      fill="#fff"
      className={ styles.label }
    >
      { payload.value }
    </text>
  );
};

CustomizedYAxisTick.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  payload: PropTypes.shape({
    value: PropTypes.string,
  }).isRequired,
};

const CustomizedLabel = ({
  x,
  y,
  stroke,
  value,
}) => {
  return (
    <text
      x={ x }
      y={ y }
      fill={ stroke }
      textAnchor="end"
      fontSize="0.8em"
    >
      { value }
    </text>
  );
};

CustomizedLabel.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  stroke: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
};

const Chart = ({ data = dummyData }) => {
  // eslint-disable-next-line no-underscore-dangle
  const _data = data.length > 0 ? data : dummyData;

  const lastValue = _data.length > 1 && _data[_data.length - 1].value;
  const sortedValues = _data.map((item) => item.value).sort();

  const minValue = sortedValues[0];
  const maxValue = sortedValues[sortedValues.length - 1];

  const calculateDomainMin = (value) => value - (value / 100 * 10);
  const calculateDomainMax = (value) => value + (value / 100 * 10);

  return (
    <div className={ styles.chart }>
      <ResponsiveContainer>
        <AreaChart
          data={ _data }
          margin={
            {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }
          }
        >
          <defs>
            <linearGradient
              id="colorValue"
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop
                offset="5%"
                stopColor="#F47771"
                stopOpacity={ 0.8 }
              />
              <stop
                offset="95%"
                stopColor="#F47771"
                stopOpacity={ 0 }
              />
            </linearGradient>
          </defs>
          <CartesianGrid
            horizontal={ false }
            strokeDasharray="3 3"
            stroke="rgba(175,182,207,.5)"
          />
          <XAxis
            interval="preserveStart"
            dataKey="date"
            stroke="#AFB6CF"
            height={ 45 }
            tick={ <CustomizedXAxisTick /> }
          />
          <YAxis
            mirror
            orientation="left"
            interval="preserveEnd"
            axisLine={ false }
            domain={ [ calculateDomainMin, calculateDomainMax ] }
            tickLine={ false }
            ticks={ [ minValue, maxValue ] }
            padding={ { bottom: 30 } }
            width={ 40 }
            tick={ <CustomizedYAxisTick /> }
          />
          {
            lastValue && (
              <ReferenceLine
                y={ lastValue }
                stroke="#AFB6CF"
                strokeDasharray="3 3"
                label={ <CustomizedLabel /> }
              />
            )
          }
          <Area
            type="monotone"
            dataKey="value"
            stroke="#F47771"
            fill="url(#colorValue)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

Chart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  })).isRequired,
};

export default Chart;
