import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Abbr from 'components/Abbr';
import Button from 'components/Button';
import CopyButton from 'components/CopyButton';
import DeleteDialog from 'components/DeleteDialog';
import Icon from 'components/Icon';
import IconButton from 'components/IconButton';
import { useLoader } from 'components/Loader';
import LoginButton from 'components/LoginButton';
import QRcodeModal from 'components/QRcodeModal';
import Share from 'components/Share';
import { Heading, Paragraph } from 'components/Typography';

import { getAbbrById } from 'store/reducers/abbr';
import { getUser } from 'store/reducers/user';
import { useMedia } from 'shared/helpers/hocs/withMedia';
import useAction from 'shared/helpers/hooks/useAction';

import Views from './modules/Views';

import styles from './Abbr.module.scss';

const EditAbbr = () => {
  const updateInterval = useRef(0);
  const { id } = useParams();
  const history = useHistory();
  const {
    getAbbr,
    getClickCount,
    updateAbbr,
  } = useAction();
  const [ isLoading ] = useLoader();
  const {
    isDesktop,
  } = useMedia();

  const abbr = useSelector(getAbbrById(id));
  const { isLoggedIn } = useSelector(getUser);

  useEffect(() => {
    getAbbr(id)
      .then(() => isLoggedIn && getClickCount(id));

    if (isLoggedIn) {
      updateInterval.current = setInterval(() => {
        getClickCount(id);
      }, 10000);
    }

    return () => {
      clearInterval(updateInterval.current);
    };
  }, [ id ]);

  useEffect(() => {
    if (!abbr.id) {
      history.push('/create');
    }
  }, [ abbr.id ]);

  const handleAbbrChange = (abbrData) => updateAbbr({
    id: abbr.id,
    ...abbrData,
  });

  if (!abbr.id) return null;

  return (
    <div
      className={ styles.page }
      key={ abbr.id }
    >
      {
        isDesktop && (
          <Heading
            type="h2"
            className={ styles.heading }
          >
            Abbr details
          </Heading>
        )
      }

      <div className={ styles.abbr }>
        <Abbr
          abbrId={ abbr.id }
          onChange={ handleAbbrChange }
          showFeatures
        />
      </div>

      <div className={ styles.actions }>
        {
          !isDesktop && (
            <div className={ styles.actionsLeft }>
              <DeleteDialog
                id={ id }
                abbr={ `${ abbr.domain }/${ abbr.slashtag }` }
                location="abbr_details"
              >
                {
                  ({ openModal }) => (
                    <IconButton
                      variant="danger"
                      icon="trash"
                      disabled={ isLoading }
                      onClick={ openModal }
                    />
                  )
                }
              </DeleteDialog>
            </div>
          )
        }
        <div className={ styles.actionsRight }>
          {
            isDesktop && (
              <div className={ styles.action }>
                <CopyButton
                  className={ styles.action }
                  permalink={ `https://${ abbr.domain }/${ abbr.slashtag }` }
                  data-location="abbr_details"
                />
              </div>
            )
          }
          <div className={ styles.action }>
            {
              !isDesktop ? (
                <IconButton
                  href={ `https://${ abbr.domain }/${ abbr.slashtag }` }
                  target="_blank"
                  icon="external"
                  variant="secondary"
                  data-abbr="check"
                  data-location="abbr_details"
                  block
                />
              ) : (
                <Button
                  href={ `https://${ abbr.domain }/${ abbr.slashtag }` }
                  target="_blank"
                  variant="secondary"
                  data-abbr="check"
                  data-location="abbr_details"
                >
                  <Icon icon="external" />
                  Check
                </Button>
              )
            }
          </div>
          <div className={ styles.action }>
            <Share
              title={ abbr.preview?.title }
              text={ abbr.preview?.description }
              url={ `https://${ abbr.domain }/${ abbr.slashtag }` }
              buttonProps={
                {
                  small: !isDesktop,
                  'data-location': 'abbr_details',
                }
              }
            />
          </div>
          <div className={ styles.action }>
            <QRcodeModal permalink={ `https://${ abbr.domain }/${ abbr.slashtag }` }>
              {
                ({ openModal }) => (
                  <IconButton
                    icon="qrcode"
                    variant="secondary"
                    shape={ isDesktop ? 'square' : 'round' }
                    onClick={ openModal }
                  />
                )
              }
            </QRcodeModal>
          </div>
          {
            !isDesktop && (
              <div className={ styles.action }>
                <CopyButton
                  className={ styles.action }
                  permalink={ `https://${ abbr.domain }/${ abbr.slashtag }` }
                  data-location="abbr_details"
                  small
                />
              </div>
            )
          }
          {
            isDesktop && (
              <DeleteDialog
                id={ id }
                abbr={ `${ abbr.domain }/${ abbr.slashtag }` }
                location="abbr_details"
              >
                {
                  ({ openModal }) => (
                    <div className={ styles.action }>
                      <Button
                        variant="danger"
                        onClick={ openModal }
                      >
                        <Icon icon="trash" />
                        Delete
                      </Button>
                    </div>
                  )
                }
              </DeleteDialog>
            )
          }
        </div>
      </div>

      {
        isLoggedIn ? (
          <Views data={ abbr.metrics?.views?.data || [] } />
        ) : (
          <div className={ styles.viewsHolder }>
            <Paragraph
              marginBottom="16"
              align="center"
            >
              Log in to see analytics
            </Paragraph>
            <LoginButton variant="secondary" />
          </div>
        )
      }
    </div>
  );
}
;

export default EditAbbr;
