import React from 'react';
import { NavLink } from 'react-router-dom';

import AbbrIcon from 'components/AbbrIcon';

import styles from './Selector.module.scss';

const Selector = () => {
  return (
    <nav className={ styles.selector }>
      <ul className={ styles.list }>
        <li className={ styles.item }>
          <NavLink
            to="/create/short-link"
            className={ styles.link }
          >
            <AbbrIcon type="redirect" />
            <span className={ styles.label }>Short Link</span>
          </NavLink>
        </li>
        <li className={ styles.item }>
          <NavLink
            to="/create/nanolanding"
            className={ styles.link }
          >
            <AbbrIcon type="page" />
            <span className={ styles.label }>Nanolanding</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Selector;
