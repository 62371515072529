import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';
import Motion from 'components/Motion';

import styles from './Loader.module.scss';

const Loader = ({ children }) => {
  return (
    <Motion>
      <div className={ styles.loader }>
        <Icon className={ styles.spinner } icon="loading" />
        { children }
      </div>
    </Motion>
  );
};

Loader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Loader;
