import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import QRcode from 'qrcode.react';

import Button from 'components/Button';
import { Heading, Paragraph } from 'components/Typography';

import styles from './QRcodeModal.module.scss';

const QRcodeView = ({ permalink }) => {
  const [ downloadLink, setDownloadLink ] = useState('https://');

  useLayoutEffect(() => {
    const canvas = document.getElementById('qrcode');

    function getSvg(svgEl) {
      svgEl.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
      const svgData = svgEl.outerHTML;
      const preface = '<?xml version="1.0" standalone="no"?>\r\n';
      const svgBlob = new Blob([ preface, svgData ], { type: 'image/svg+xml;charset=utf-8' });

      return URL.createObjectURL(svgBlob);
    }

    setDownloadLink(getSvg(canvas));
  }, [ permalink ]);

  return (
    <>
      <Heading
        type="h4"
        size={ [ 'h4', 'h3' ] }
        marginBottom="8"
      >
        QR-code
      </Heading>

      <Paragraph
        marginBottom="8"
      >
        This code can be used to share your abbr with printing products, billboards and banners.
        <br />
        Also you can share abbr with friends straight from your phone screen.
      </Paragraph>

      <div
        className={ styles.wrapper }
      >
        <QRcode
          id="qrcode"
          className={ styles.img }
          renderAs="svg"
          value={ `https://${ permalink }` }
        />
      </div>

      <div className={ styles.button }>
        <Button
          href={ downloadLink }
          download={ permalink.replace(/[./]/g, '_') }
          block
          data-qrcode="download"
        >
          Download
        </Button>
      </div>
    </>
  );
};

QRcodeView.propTypes = {
  permalink: PropTypes.string.isRequired,
};

export default QRcodeView;
