import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'components/Button';
import Icon from 'components/Icon';
import { Heading, Paragraph } from 'components/Typography';

import MessengerList from './components/MessengerList/MessengerList';

import styles from './Subscription.module.scss';


const Subscription = () => {
  const history = useHistory();

  const skipSubscription = () => {
    history.push(history.location.state.referrer);
  };

  return (
    <div className={ styles.page }>
      <Heading
        marginTop="24"
        type="h3"
        className={ styles.heading }
        align={ [ 'left', 'center' ] }
      >
        Subscribe to notifications
      </Heading>

      <Paragraph
        marginTop="8"
        align={ [ 'left', 'center' ] }
      >
        Be the first to know about new
        awesome features and offers!
      </Paragraph>

      <div className={ styles.list }>
        <MessengerList />
      </div>

      <Paragraph
        marginTop="16"
        align={ [ 'left', 'center' ] }
      >
        The list of available messengers will continue to grow.
      </Paragraph>

      <div className={ styles.action }>
        <Button
          variant="ghost"
          onClick={ skipSubscription }
        >
          Skip
          <Icon icon="arrow-right" />
        </Button>
      </div>
    </div>
  );
};

export default Subscription;
