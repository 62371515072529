import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import MessengerList from 'pages/Subscription/components/MessengerList';

import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Divider from 'components/Divider';
import { Paragraph } from 'components/Typography';

import { getUser } from 'store/reducers/user';
import { useMedia } from 'shared/helpers/hocs/withMedia';
import useAction from 'shared/helpers/hooks/useAction';

import styles from '../../Profile.module.scss';

const Notifications = () => {
  const { isDesktop } = useMedia();
  const { updateUser } = useAction();
  const {
    id,
    notifications: notificationSettings,
  } = useSelector(getUser);

  const [ notifications, setNotifications ] = useState(notificationSettings);

  const handleChange = (e) => {
    setNotifications({
      ...notifications,
      [e.target.name]: e.target.checked,
    });
  };

  const saveSettings = () => {
    updateUser({
      id,
      notifications,
    });
  };

  return (
    <div className={ styles.notificationsPage }>
      <div className={ styles.subscription }>
        <Paragraph marginBottom="16">
          Subscribe to notifications in your favourite messenger!
        </Paragraph>

        <MessengerList />

        <Paragraph marginTop="16">
          The list of available messengers will continue to grow.
        </Paragraph>
      </div>

      { !isDesktop && <Divider marginTop="24" marginBottom="24" /> }

      <div className={ styles.notifications }>
        <Paragraph marginBottom="16">
          Choose what type of notifications you want to receive:
        </Paragraph>

        <div className={ styles.field }>
          <Checkbox
            onChange={ handleChange }
            name="productUpdates"
            checked={ notifications.productUpdates }
            caption="Be the first to hear about abbr updates, new features"
          >
            Product Updates
          </Checkbox>
        </div>
        <div className={ styles.field }>
          <Checkbox
            onChange={ handleChange }
            name="news"
            checked={ notifications.news }
            caption="Learn what we built, why we built it, and how to use it."
          >
            News
          </Checkbox>
        </div>
        <div className={ styles.field }>
          <Checkbox
            onChange={ handleChange }
            name="offers"
            checked={ notifications.offers }
            caption="Exclusive deals, nice upgrades, and other temptations."
          >
            Offers
          </Checkbox>
        </div>
        <div className={ styles.field }>
          <Checkbox
            onChange={ handleChange }
            name="service"
            checked={ notifications.service }
            caption="The most important notifications affecting your interactions with the service"
          >
            Service messages
          </Checkbox>
        </div>
        <div className={ styles.field }>
          <Button
            variant="success"
            onClick={ saveSettings }
            block="mobile"
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
