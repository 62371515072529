import React from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Divider from 'components/Divider';
import Icon from 'components/Icon';
import PixelManager from 'components/PixelManager';
import { Heading, Paragraph } from 'components/Typography';

import { getAbbrBySlashtag } from 'store/reducers/abbrs';
import { getUser } from 'store/reducers/user';
import useAction from 'shared/helpers/hooks/useAction';

import { AUTH_URL } from 'shared/constants';

import styles from './PixelModal.module.scss';

const Pixels = ({
  abbrSlashtag,
  onSave,
}) => {
  const {
    id,
    tracking,
  } = useSelector(getAbbrBySlashtag(abbrSlashtag));
  const { isLoggedIn } = useSelector(getUser);

  const { updateAbbr } = useAction();

  const updateTracking = (value) => {
    if (isEqual(tracking, value)) {
      onSave();

      return Promise.reject();
    }

    return updateAbbr({
      id,
      tracking: value,
    }).then(() => {
      onSave();

      return Promise.resolve();
    });
  };

  return (
    <>
      <Heading
        type="h4"
        size={ [ 'h4', 'h3' ] }
        marginBottom="8"
      >
        Tracking Pixels
      </Heading>

      <Paragraph marginBottom="16">
        Tracking pixels are used to track user activity on your links and pages. They allow you to define user preferences and help in creating marketing campaigns.
      </Paragraph>

      {
        isLoggedIn
          ? (
            <div className={ styles.manager }>
              <PixelManager
                onChange={ updateTracking }
                tracking={ tracking }
              />
            </div>
          )
          : (
            <>
              <Divider />

              <Paragraph marginBottom="16">
                This feature available only for logged in users
              </Paragraph>

              <Button
                variant="secondary"
                href={ AUTH_URL }
                block
              >
                Log In
                <Icon icon="login" />
              </Button>
            </>
          )
      }
    </>
  );
};

Pixels.propTypes = {
  abbrSlashtag: PropTypes.string.isRequired,
  onSave: PropTypes.func,
};

Pixels.defaultProps = {
  onSave: () => {
  },
};

export default Pixels;
