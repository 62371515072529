import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import Button from 'components/Button';
import Container from 'components/Container';
import Icon from 'components/Icon';
import Logo from 'components/Logo';
import { Heading } from 'components/Typography';
import UserBlock from 'components/UserBlock';

import { useMedia } from 'shared/helpers/hocs/withMedia';

import styles from './Header.module.scss';

const Header = () => {
  const history = useHistory();
  const { isDesktop } = useMedia();

  return (
    <Container>
      <header className={ styles.header }>
        {
          !isDesktop ? (
            <Switch>
              <Route
                path="/create/:type"
                exact
              >
                <Heading
                  align="center"
                  className={ styles.heading }
                  type="h1"
                  size="h2"
                >
                  Create new abbr
                </Heading>
              </Route>
              <Route
                path="/create/:type/:id"
                exact
              >
                <Heading
                  align="center"
                  className={ styles.heading }
                  type="h1"
                  size="h2"
                >
                  Upgrade and share
                </Heading>
              </Route>
              <Route
                path="/abbrs"
                exact
              >
                <Heading
                  align="center"
                  className={ styles.heading }
                  type="h1"
                  size="h2"
                >
                  Your abbrs
                </Heading>
              </Route>
              <Route
                path="/abbrs/:id"
                exact
              >
                <Heading
                  align="center"
                  className={ styles.heading }
                  type="h1"
                  size="h2"
                >
                  Abbr details
                </Heading>
              </Route>
              <Route
                path="/profile"
                exact
              >
                <Heading
                  align="center"
                  className={ styles.heading }
                  type="h1"
                  size="h2"
                >
                  Profile
                </Heading>
              </Route>
              <Route
                path="/profile/notifications"
                exact
              >
                <Heading
                  align="center"
                  className={ styles.heading }
                  type="h1"
                  size="h2"
                >
                  Notifications
                </Heading>
              </Route>
              <Route
                path="*"
              >
                <Logo
                  size="sm"
                  variant="invert"
                  to="/"
                />
              </Route>
            </Switch>
          ) : (
            <Logo
              size="md"
              variant="invert"
              to="/"
            />
          )
        }

        {
          !isDesktop ? (
            <Switch>
              <Route
                path="/abbrs/:id"
                exact
              >
                <Button
                  variant="ghost"
                  to="/abbrs"
                >
                  Back to list
                  <Icon icon="back" />
                </Button>
              </Route>
              <Route
                path={ [ '/profile', '/profile/notifications' ] }
                exact
              >
                <Button
                  variant="ghost"
                  onClick={ history.goBack }
                >
                  Back
                  <Icon icon="back" />
                </Button>
              </Route>
              <Route
                path="*"
              >
                <UserBlock />
              </Route>
            </Switch>
          ) : (
            <UserBlock />
          )
        }
      </header>
    </Container>
  );
};

export default Header;
