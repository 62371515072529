import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';

// import Cookie from 'js-cookie';
import RedirectPage from 'pages/RedirectPage';

import { getUser } from 'store/reducers/user';

const ProtectedRoute = (props) => {
  const { isLoggedIn } = useSelector(getUser);
  const history = useHistory();

  useEffect(() => {
    if (!isLoggedIn) {
      history.push('/');
    }
  }, [ isLoggedIn ]);

  return isLoggedIn
    ? <Route { ...props } />
    : <RedirectPage />;
};

export default ProtectedRoute;
