/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useEffect, useState } from 'react';
import { useEmblaCarousel } from 'embla-carousel/react';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';

import styles from './Carousel.module.scss';

const Carousel = ({
  children,
  fixedChildren,
}) => {
  const [ viewportRef, embla ] = useEmblaCarousel();
  const [ selectedIndex, setSelectedIndex ] = useState(0);
  const [ scrollSnaps, setScrollSnaps ] = useState([]);

  const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [ embla ]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
  }, [ embla, setSelectedIndex ]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on('select', onSelect);
  }, [ embla, setScrollSnaps, onSelect ]);

  const renderSlides = React.Children.map(children, (child) => (
    <div
      className={ styles.slide }
      key={ uniqueId() }
    >
      <div className={ styles.slideInner }>
        { child }
      </div>
    </div>
  ));

  const renderDots = scrollSnaps.map((_, index) => (
    <button
      key={ uniqueId() }
      className={ styles[index === selectedIndex ? 'dot-selected' : 'dot'] }
      type="button"
      onClick={ () => scrollTo(index) }
    />
  ));

  return (
    <>
      <div className={ styles.carousel }>
        <div
          className={ styles.viewport }
          ref={ viewportRef }
        >
          <div className={ styles.container }>
            { renderSlides }
          </div>
        </div>
      </div>
      <div className={ styles.fixed }>
        { fixedChildren }
      </div>
      <div className={ styles.dots }>
        { renderDots }
      </div>
    </>
  );
};

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  fixedChildren: PropTypes.node,
};

Carousel.defaultProps = {
  fixedChildren: null,
};

export default Carousel;
