import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import { useLoader } from 'components/Loader';
import Modal, { useModal, withModal } from 'components/Modal';
import { Heading, Paragraph } from 'components/Typography';

import { useMedia } from 'shared/helpers/hocs/withMedia';
import useAction from 'shared/helpers/hooks/useAction';

import styles from './DeleteDialog.module.scss';

const DeleteDialog = ({
  id,
  abbr,
  onModalOpen,
  onModalClose,
  onDeleted,
  children,
  location
}) => {
  const {
    openModal,
    closeModal,
    isOpen,
    closing,
  } = useModal();
  const [ isLoading, setLoading ] = useLoader();
  const { deleteAbbr } = useAction();
  const { isDesktop } = useMedia();

  const handleDeleteAbbr = useCallback(() => {
    setLoading(true);

    onDeleted();

    deleteAbbr(id)
      .finally(() => {
        setLoading(false);
      });
  }, [ deleteAbbr, id ]);

  return (
    <>
      { children({ openModal }) }
      {
        (isOpen || closing) && (
          <Modal
            onOpen={ onModalOpen }
            onClose={ onModalClose }
            animation={ !isDesktop ? 'slide-bottom' : 'slide-top' }
            position={ !isDesktop ? 'bottom-center' : 'top-center' }
            label="Share Link"
          >
            <div className={ styles.content }>
              <Heading size="h4">
                Delete
                { ' ' }
                <span className={ styles.abbr }>{ abbr }</span>
                ?
              </Heading>
              <Paragraph marginTop="8">
                Abbr will be deleted permanently and cannot be restored
              </Paragraph>
              <div className={ styles.actions }>

                <div className={ styles.action }>
                  <Button
                    variant="danger"
                    onClick={ handleDeleteAbbr }
                    disabled={ isLoading }
                    block
                    data-abbr="delete"
                    data-location={ location }
                  >
                    Delete
                  </Button>
                </div>
                <div className={ styles.action }>
                  <Button
                    variant="secondary"
                    onClick={ closeModal }
                    disabled={ isLoading }
                    block
                  >
                    Oh no! I want to keep it
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        )
      }
    </>
  );
};

DeleteDialog.propTypes = {
  children: PropTypes.func.isRequired,
  onModalOpen: PropTypes.func,
  onModalClose: PropTypes.func,
  id: PropTypes.string.isRequired,
  abbr: PropTypes.string.isRequired,
  onDeleted: PropTypes.func,
  location: PropTypes.string.isRequired
};

DeleteDialog.defaultProps = {
  onModalOpen: () => {
  },
  onModalClose: () => {
  },
  onDeleted: () => {
  },
};

export default withModal(DeleteDialog);
