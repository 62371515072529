import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const variants = {
  fade: {
    initial: {
      opacity: 0,
    },
    active: {
      opacity: 1,
    },
  },
};

const Motion = ({
  type,
  children,
}) => {
  return (
    <motion.div
      initial="initial"
      animate="active"
      exit="initial"
      variants={ variants[type] }
    >
      { children }
    </motion.div>
  );
};

Motion.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Motion.defaultProps = {
  type: 'fade',
};

export default Motion;
