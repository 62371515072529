import React, { useState } from 'react';

import { LoaderContext } from './loaderContext';
import TopLoader from './TopLoader';

function withLoader(WrappedComponent) {
  function WithLoader(props) {
    const [ isLoading, setLoading ] = useState(false);

    const contextValues = [
      isLoading,
      setLoading,
    ];

    return (
      <LoaderContext.Provider value={ contextValues }>
        <TopLoader />
        <WrappedComponent { ...props } />
      </LoaderContext.Provider>
    );
  }

  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  WithLoader.displayName = `withLoader(${ wrappedComponentName })`;

  return WithLoader;
}

export default withLoader;
