import { useEffect, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';

export default function useDebounce(value, delay) {
  const handler = useRef(0);
  const [ debouncedValue, setDebouncedValue ] = useState(value);

  useEffect(
    () => {
      if (!isEqual(value, debouncedValue)) {
        handler.current = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
      }

      return () => {
        clearTimeout(handler.current);
      };
    },
    [ debouncedValue, delay, value ],
  );

  return debouncedValue;
}
