import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';

import styles from './IconButton.module.scss';

const cn = classnames.bind(styles);

const IconButton = ({
  className,
  icon,
  size,
  to,
  href,
  type,
  variant,
  target,
  rel,
  download,
  shape,
  fill,
  disabled,
  onClick,
  ...restProps
}) => {
  const buttonClassName = cn(
    variant,
    disabled && 'disabled',
    shape && `shape-${ shape }`,
    fill && 'fill',
    size && `size-${ size }`,
    className,
  );

  let ButtonComponent = null;

  switch (true) {
    case to !== null:
      ButtonComponent = Link;

      break;
    case typeof href === 'string':
      ButtonComponent = 'a';

      break;
    default:
      ButtonComponent = 'button';
  }

  return (
    <ButtonComponent
      to={ to }
      href={ href }
      type={ type }
      target={ target }
      rel={ rel }
      download={ download }
      className={ buttonClassName }
      onClick={ !disabled ? onClick : undefined }
      { ...restProps }
    >
      <Icon
        className={ styles.icon }
        icon={ icon }
        size={ size }
      />
    </ButtonComponent>
  );
};

IconButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  shape: PropTypes.oneOf([ 'round', 'square' ]),
  to: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
  size: PropTypes.oneOf([ '12', '16', '20', '24', '32' ]),
  href: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.oneOf([ 'primary', 'secondary', 'success', 'danger', 'warning', 'ghost' ]),
  target: PropTypes.oneOf([ '_blank', '_top', '_parent' ]),
  rel: PropTypes.string,
  disabled: PropTypes.bool,
  download: PropTypes.bool,
  fill: PropTypes.bool,
  onClick: PropTypes.func,
};

IconButton.defaultProps = {
  className: '',
  shape: 'round',
  fill: false,
  to: null,
  size: '20',
  href: null,
  type: 'button',
  variant: 'primary',
  target: undefined,
  rel: undefined,
  download: null,
  disabled: false,
  onClick: () => {
  },
};

export default IconButton;
