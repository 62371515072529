import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { useMedia } from 'shared/helpers/hocs/withMedia';

import Main from './screens/Main';
import Notifications from './screens/Notifications';

const Profile = () => {
  const { path } = useRouteMatch();
  const { isDesktop } = useMedia();

  console.log(path);

  return (
    <Switch>
      {
        !isDesktop && (
          <Route
            path="/profile/notifications"
            exact
          >
            <Notifications />
          </Route>
        )
      }
      <Route
        path="/profile"
        exact
      >
        <Main />
      </Route>
    </Switch>
  );
};

export default Profile;
