import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import Container from 'components/Container';
import CookieConsent from 'components/CookieConsent/CookieConsent';
import { Heading, Paragraph } from 'components/Typography';

import styles from './Footer.module.scss';

const Footer = () => {
  const year = useRef((new Date()).getFullYear());

  return (
    <footer className={ styles.footer }>
      <Container className={ styles.grid }>
        <div className={ styles.contacts }>
          <div className={ styles.contact }>
            <Heading
              className={ styles.label }
              type="p"
              size={ [ 'h5', 'h4' ] }
              marginBottom={ [ null, '4' ] }
            >
              Contact us
            </Heading>
            <a
              href="mailto:support@abbr.studio"
              className={ styles.link }
              data-contact="support"
              data-location="footer"
            >
              support@abbr.studio
            </a>
          </div>

          <div className={ styles.contact }>
            <Heading
              className={ styles.label }
              type="p"
              size={ [ 'h5', 'h4' ] }
              marginBottom={ [ null, '4' ] }
            >
              Report abuse
            </Heading>
            <a
              href="mailto:abuse@abbr.studio"
              className={ styles.link }
              data-contact="abuse"
              data-location="footer"
            >
              abuse@abbr.studio
            </a>
          </div>
        </div>

        <nav className={ styles.nav }>
          <ul>
            <li className={ styles.item }>
              <CookieConsent closeOnOverlayClick>
                {
                  ({ openModal }) => (
                    <button
                      className={ styles.link }
                      type="button"
                      onClick={ openModal }
                    >
                      Configure Cookies
                    </button>
                  )
                }
              </CookieConsent>
            </li>
            <li className={ styles.item }>
              <Link
                to="/terms"
                className={ styles.link }
                data-legal="terms"
                data-location="footer"
              >
                Terms & Conditions
              </Link>
            </li>
            <li className={ styles.item }>
              <Link
                to="/privacy-policy"
                className={ styles.link }
                data-legal="privacy_policy"
                data-location="footer"
              >
                Privacy & Cookies Policy
              </Link>
            </li>
          </ul>
        </nav>

        <Paragraph
          className={ styles.copyright }
          marginTop={ [ '24', '4' ] }
        >
          { year.current }
          { ' ' }
          © Abbr Studio
          { ' ' }
          • All rights reserved
        </Paragraph>
      </Container>
    </footer>
  );
};

Footer.propTypes = {};

export default Footer;
