import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import union from 'lodash/union';
import without from 'lodash/without';
import PropTypes from 'prop-types';

import AbbrIcon from 'components/AbbrIcon';
import Achievement from 'components/Achievement';
import DomainModal from 'components/DomainModal';
import PixelModal from 'components/PixelModal';
import PreviewModal from 'components/PreviewModal';
import SlashtagField from 'components/SlashtagField';
import TitleField from 'components/TitleField';
import { Caption } from 'components/Typography';
import UrlField from 'components/UrlField';

import { getAbbrById } from 'store/reducers/abbr';
import { getCurrentAbbr } from 'store/reducers/draft';
import useAction from 'shared/helpers/hooks/useAction';

import { getUser } from '../../store/reducers/user';
import SlashtagPlaceholder from '../SlashtagField/SlashtagPlaceholder';

import styles from './Abbr.module.scss';

const Abbr = ({
  abbrId,
  showFeatures,
  onError,
  onValid,
}) => {
  const titleRef = useRef();
  const slashtagRef = useRef();

  const {
    type,
    name,
    domain,
    slashtag,
    url,
    tracking,
    customize,
  } = useSelector(abbrId ? getAbbrById(abbrId) : getCurrentAbbr);

  const { isLoggedIn } = useSelector(getUser);

  const {
    updateAbbr,
    updateCreatedAbbr,
  } = useAction();

  const slashtagUpdated = customize.includes('abbr');
  const titleUpdated = customize.includes('name');
  const trackingProgress = (!!tracking.google + !!tracking.facebook) * 0.5;

  const handleChange = ({
    customizeData,
    ...data
  }) => {
    let newCustomize = customize;

    if (customizeData) {
      Object.keys(customizeData)
        .forEach((prop) => {

          if (customizeData[prop]) {
            newCustomize = union(newCustomize, [ prop ]);
          } else {
            newCustomize = without(newCustomize, prop);
          }
        });
    }

    if (abbrId) {
      updateAbbr({
        id: abbrId,
        ...data,
        customize: [ ...newCustomize ],
      });
    } else {
      updateCreatedAbbr({
        ...data,
        customize: [ ...newCustomize ],
      });
    }
  };

  const focusTitle = () => {
    titleRef.current.focus();
    titleRef.current.select();
  };

  const focusSlashtag = () => {
    slashtagRef.current.focus();
    slashtagRef.current.select();
  };

  useEffect(() => {
    if (slashtag && url) {
      onValid();
    } else {
      onError({
        slashtag: !!slashtag,
        url: !!url,
      });
    }
  }, [ slashtag, url ]);

  return (
    <article className={ styles.wrapper }>
      <div className={ styles.main }>
        <div className={ styles.abbr }>

          <div className={ styles.type }>
            <AbbrIcon
              size="24"
              type={ type }
            />
          </div>


          <div className={ styles.firstRow }>
            <TitleField
              inputRef={ titleRef }
              value={ name }
              onChange={ handleChange }
            />
          </div>
          <div className={ styles.secondRow }>
            <div className={ styles.row }>
              { domain }
              /
              {
                isLoggedIn ? (
                  <SlashtagField
                    inputRef={ slashtagRef }
                    value={ slashtag }
                    updated={ slashtagUpdated }
                    onChange={ handleChange }
                  />
                ) : (
                  <SlashtagPlaceholder value={ slashtag } />
                )
              }
            </div>
          </div>
        </div>

        {
          url && showFeatures && (
            <Caption
              className={ styles.url }
              type="div"
              marginTop={ [ '16', '24' ] }
            >
              <UrlField
                value={ url }
                onChange={ handleChange }
              />
            </Caption>
          )
        }
      </div>

      {
        showFeatures && (
          <>
            <div className={ styles.features }>
              <ul className={ styles.featuresList }>
                <li className={ styles.featuresItem }>
                  <Achievement
                    label="Add Originality"
                    onClick={ focusSlashtag }
                    progress={ slashtagUpdated }
                  />
                </li>
                <li className={ styles.featuresItem }>
                  <Achievement
                    label="Name Your Abbr"
                    onClick={ focusTitle }
                    progress={ titleUpdated }
                  />
                </li>
                <li className={ styles.featuresItem }>
                  <PixelModal abbrSlashtag={ slashtag }>
                    {
                      ({
                        isOpen,
                        openModal,
                      }) => (
                        <Achievement
                          label="Track Your Audience"
                          icon="plus"
                          progress={ trackingProgress }
                          active={ isOpen }
                          onClick={ openModal }
                        />
                      )
                    }
                  </PixelModal>
                </li>
                <li className={ styles.featuresItem }>
                  <PreviewModal abbrSlashtag={ slashtag }>
                    {
                      ({
                        isOpen,
                        openModal,
                      }) => (
                        <Achievement
                          label="See How It Looks"
                          active={ isOpen }
                          onClick={ openModal }
                        />
                      )
                    }
                  </PreviewModal>
                </li>
                <li className={ styles.featuresItem }>
                  <DomainModal>
                    {
                      ({
                        isOpen,
                        openModal,
                      }) => (
                        <Achievement
                          label="Custom Domain"
                          active={ isOpen }
                          onClick={ openModal }
                        />
                      )
                    }
                  </DomainModal>
                </li>
              </ul>
            </div>
          </>
        )
      }
    </article>
  );
};

Abbr.propTypes = {
  abbrId: PropTypes.string,
  onError: PropTypes.func,
  onValid: PropTypes.func,
  showFeatures: PropTypes.bool,
};

Abbr.defaultProps = {
  abbrId: null,
  showFeatures: false,
  onError: () => {
  },
  onValid: () => {
  },
};

export default Abbr;
