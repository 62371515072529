/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useCallback, useEffect, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames/bind';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';
import { Caption } from 'components/Typography';

import useDoubleClick from 'shared/helpers/hooks/useDoubleClick';

import styles from './Input.module.scss';

const cn = classnames.bind(styles);

const Input = ({
  inputRef,
  textarea,
  type,
  label,
  value,
  placeholder,
  disabled,
  autoFocus,
  autoComplete,
  error,
  success,
  caption,
  hint,
  onHintOpen,
  required,
  startAdornment,
  endAdornment,
  onChange,
  onKeyPress,
  onFocus,
  onBlur,
  onPaste,
}) => {
  const inputId = useRef(uniqueId('input-'));
  const tooltipId = useRef(uniqueId('tooltip-'));
  const innerRef = useRef();
  const handleClick = useDoubleClick({
    onDoubleClick: (e) => e.target.select(),
  });

  const className = cn(
    disabled && 'disabled',
    textarea && 'textarea',
    'field',
    error && 'error',
    success && 'success',
  );

  const InputComponent = textarea ? 'textarea' : 'input';

  const handleAutoSize = useCallback((e) => {
    const { target } = e;


    target.style.height = 'inherit';
    target.style.height = `${ Math.min(target.scrollHeight, 300) }px`;
  }, []);

  useEffect(() => {
    textarea && handleAutoSize();
  }, []);

  return (
    <div className={ className }>
      {
        label && (
          <div
            className={ styles.label }
          >
            <div className={ styles.labelWrapper }>
              <label htmlFor={ inputId.current }>
                { label }
              </label>

              {
                hint && (
                  <>
                    <span
                      className={ styles.hint }
                      data-tip="custom show"
                      data-for={ tooltipId.current }
                      data-event="focus"
                      data-event-off="blur"
                      tabIndex="0"
                    >
                      <Icon icon="help" />
                    </span>

                    <ReactTooltip
                      className={ styles.tooltip }
                      id={ tooltipId.current }
                      place="top"
                      effect="solid"
                      delayHide={ 10 }
                      delayUpdate={ 10 }
                      afterShow={ onHintOpen }
                    >
                      { hint }
                    </ReactTooltip>
                  </>
                )
              }
            </div>

            {
              !required && (
                <span className={ styles.optional }>
                  up to you
                </span>
              )
            }
          </div>
        )
      }
      <div className={ styles.inner }>
        <div
          className={ styles.startAdornment }
        >
          { startAdornment }
        </div>
        <InputComponent
          id={ inputId.current }
          ref={ inputRef || innerRef }
          type={ type }
          className={ styles.input }
          value={ value }
          autoComplete={ autoComplete }
          autoFocus={ autoFocus }
          placeholder={ placeholder }
          onChange={ onChange }
          onKeyPress={ onKeyPress }
          onFocus={ onFocus }
          onBlur={ onBlur }
          onInput={ textarea ? handleAutoSize : undefined }
          onPaste={ onPaste }
          onClick={ handleClick }
          disabled={ disabled }
        />
        <div className={ styles.endAdornment }>
          { endAdornment }
        </div>
        <div className={ styles.background } />
      </div>
      {
        caption && (
          <div className={ styles.caption }>
            <Caption>{ caption }</Caption>
          </div>
        )
      }
    </div>
  );
};

Input.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  required: PropTypes.bool,
  textarea: PropTypes.bool,
  type: PropTypes.oneOf([ 'text', 'number', 'email', 'password', 'tel', 'url' ]),
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
  caption: PropTypes.string,
  hint: PropTypes.node,
  onHintOpen: PropTypes.func,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onPaste: PropTypes.func,
};

Input.defaultProps = {
  inputRef: null,
  textarea: false,
  type: 'text',
  required: true,
  label: null,
  value: '',
  placeholder: '',
  disabled: false,
  success: false,
  error: false,
  caption: null,
  hint: null,
  onHintOpen: () => {
  },
  autoComplete: null,
  autoFocus: undefined,
  startAdornment: null,
  endAdornment: null,
  onKeyPress: () => {
  },
  onFocus: () => {
  },
  onBlur: () => {
  },
  onPaste: () => {
  },
};

export default Input;
