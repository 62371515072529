import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';

import styles from './LoggedIn.module.scss';

const LoggedIn = ({
  name,
  avatar,
}) => {
  const { pathname } = useLocation();

  return (
    <Link
      to={
        {
          pathname: '/profile',
          state: {
            referrer: pathname,
          },
        }
      }
      className={ styles.link }
    >
      <div className={ styles.name }>
        { name || 'Welcome back!' }
      </div>
      <div className={ styles.avatar }>
        {
          avatar ? (
            <img
              src={ avatar }
              alt={ name }
              className={ styles.avatarImage }
            />
          ) : (
            <Icon icon="user" />
          )
        }
      </div>
    </Link>
  );
};

LoggedIn.propTypes = {
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string,
};

LoggedIn.defaultProps = {
  avatar: null,
};

export default LoggedIn;
