import debounce from 'lodash/debounce';

const useDoubleClick = ({
  onSingleClick,
  onDoubleClick,
}) => {
  let clickedOnce = false;
  let delayedClick = false;

  const handleSingleClick = (e) => {
    clickedOnce = false;
    typeof onSingleClick === 'function' && onSingleClick(e);
  };

  const handleDoubleClick = (e) => {
    typeof onDoubleClick === 'function' && onDoubleClick(e);
  };

  return (e) => {
    if (!delayedClick) {
      delayedClick = debounce(handleSingleClick, 500);
    }

    if (clickedOnce) {
      delayedClick.cancel();
      clickedOnce = false;

      handleDoubleClick(e);
    } else {
      delayedClick(e);
      clickedOnce = true;
    }
  };
};

export default useDoubleClick;
