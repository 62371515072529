import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import { useLoader } from 'components/Loader';
import Modal, { useModal, withModal } from 'components/Modal';
import { Heading, Paragraph } from 'components/Typography';

import { getCookies } from 'store/reducers/cookies';
import { useMedia } from 'shared/helpers/hocs/withMedia';
import useAction from 'shared/helpers/hooks/useAction';

import ConfirmationDialog from './ConfirmationDialog';

import styles from './CookieConsent.module.scss';

const CookieConsent = ({
  initiallyOpen,
  closeOnOverlayClick,
  children,
}) => {
  const cookies = useSelector(getCookies);
  const prevCookies = useRef(cookies);
  const [ checked, setChecked ] = useState(cookies);
  const {
    isOpen,
    closing,
    openModal,
    closeModal,
  } = useModal();
  const {
    setAllowedCookies,
    logoutUser,
  } = useAction();
  const [ isLoading, setLoading ] = useLoader();
  const { isDesktop } = useMedia();
  const closingTimeout = useRef(0);

  const handleChange = (e) => {
    setChecked({
      ...checked,
      [e.target.name]: e.target.checked,
    });
  };

  const handleAuthCheckboxChange = (openInnerModal) => (e) => {
    if (checked.auth) {
      openInnerModal();
    }

    handleChange(e);
  };

  const addCloseDelay = () => {
    return new Promise((resolve) => {
      setLoading(true);

      closingTimeout.current = setTimeout(() => {
        setLoading(false);
        resolve();
      }, 1000);
    });

  };

  const handleConfirmation = () => {
    setChecked({
      ...checked,
      auth: false,
    });
  };

  const handleDecline = () => {
    setChecked({
      ...checked,
      auth: true,
    });
  };

  const handleSave = () => {
    closeModal();

    setAllowedCookies(checked);

    addCloseDelay().then(() => {
      setAllowedCookies({
        consentShown: true,
      });
    });
  };

  useEffect(() => {
    if (initiallyOpen) {
      openModal();
    }
  }, []);

  useEffect(() => {
    if (prevCookies.current.auth && !cookies.auth) {
      logoutUser();

    }

    prevCookies.current = cookies;
  }, [ cookies ]);

  useEffect(() => {
    return () => {
      clearTimeout(closingTimeout.current);
    };
  }, []);

  return (
    <>
      { children({ openModal }) }
      {
        (isOpen || closing) && (
          <Modal
            animation={ !isDesktop ? 'slide-bottom' : 'slide-top' }
            position={ !isDesktop ? 'bottom-center' : 'top-center' }
            label="Cookie Consent"
            closeOnOverlayClick={ closeOnOverlayClick }
          >
            <div
              className={ styles.content }
              data-consent="visible"
            >
              <Heading
                size="h4"
              >
                We have some cookies for you...
              </Heading>
              <Paragraph
                marginTop="8"
                marginBottom="16"
              >
                We use cookies to create better experience for you. You can choose what cookies you want to
                keep:
              </Paragraph>
              <div className={ styles.field }>
                <ConfirmationDialog
                  onConfirm={ handleConfirmation }
                  onDecline={ handleDecline }
                >
                  {
                    ({ openModal: openInnerModal }) => (
                      <Checkbox
                        onChange={ handleAuthCheckboxChange(openInnerModal) }
                        name="auth"
                        checked={ checked.auth }
                        caption="If you disable them, then you won’t be able to log in or register"
                      >
                        Authorization cookies
                      </Checkbox>
                    )
                  }
                </ConfirmationDialog>
              </div>
              <div className={ styles.field }>
                <Checkbox
                  onChange={ handleChange }
                  name="analytics"
                  checked={ checked.analytics }
                  caption="If you disable them, then we won’t collect analytics and make our service better"
                >
                  Analytics cookies
                </Checkbox>
              </div>
              <div className={ styles.action }>
                <Button
                  variant="success"
                  onClick={ handleSave }
                  disabled={ isLoading }
                  data-consent={ !isEqual(cookies, checked) ? 'update' : undefined }
                  data-consent-analytics={ checked.analytics ? 'granted' : 'denied' }
                  block
                >
                  Confirm
                </Button>
              </div>
            </div>
          </Modal>
        )
      }
    </>
  );
};

CookieConsent.propTypes = {
  initiallyOpen: PropTypes.bool,
  closeOnOverlayClick: PropTypes.bool,
  children: PropTypes.func,
};

CookieConsent.defaultProps = {
  initiallyOpen: false,
  closeOnOverlayClick: false,
  children: () => {
  },
};

export default withModal(CookieConsent);
