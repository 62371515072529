import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Button from 'components/Button';
import CookieConsent from 'components/CookieConsent/CookieConsent';
import Divider from 'components/Divider';
import Editable from 'components/Editable';
import Icon from 'components/Icon';
import { useLoader } from 'components/Loader';
import { Caption, Heading, Paragraph } from 'components/Typography';

import { getUser } from 'store/reducers/user';
import { useMedia } from 'shared/helpers/hocs/withMedia';
import useAction from 'shared/helpers/hooks/useAction';

import DeleteConfirm from '../../components/DeleteConfirm/DeleteConfirm';
import styles from '../../Profile.module.scss';
import Notifications from '../Notifications';

const Main = () => {
  const [ error, setError ] = useState(null);
  const nameRef = useRef();

  const {
    logoutUser,
    updateUser,
    trackEvent,
  } = useAction();
  const [ isLoading, setLoading ] = useLoader();
  const { isDesktop } = useMedia();
  const {
    id,
    name,
    avatar,
  } = useSelector(getUser);
  const [ _name, setName ] = useState(name);

  const handleLogout = () => {
    setLoading(true);

    logoutUser()
      .then(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setName(name);
  }, [ name ]);

  useEffect(() => {
    setError(false);
  }, [ _name ]);

  const saveName = () => {
    updateUser({
      id,
      name: _name,
    })
      .then(() => {
        trackEvent('PROFILE/CHANGE_NAME');
      })
      .catch(() => {
        setError(true);
        nameRef.current.blur();
        nameRef.current.focus();
      });
  };

  const ErrorTooltip = (
    <div className={ styles.tooltip }>
      <Caption>
        There was an error while updating your name.
        <br />
        We already catch it and working on a fix.
        <br />
        Please try again later.
      </Caption>
    </div>
  );

  return (
    <div className={ styles.page }>
      {
        isDesktop && (
          <Heading
            type="h2"
            className={ styles.heading }
          >
            Profile
          </Heading>
        )
      }

      <div className={ styles.user }>
        <div className={ styles.avatar }>
          {
            avatar ? (
              <img
                src={ avatar }
                alt={ name }
                className={ styles.avatarImage }
              />
            ) : (
              <Icon
                icon="user"
                size="20"
              />
            )
          }
        </div>

        <Editable
          inputRef={ nameRef }
          value={ _name }
          onChange={ setName }
          onBlur={ saveName }
          placeholder="How we can call you?"
          tooltip={ error && ErrorTooltip }
        />

        <Button
          className={ styles.logout }
          variant="ghost"
          onClick={ handleLogout }
          disabled={ isLoading }
        >
          Log out
          <Icon icon="logout" />
        </Button>
      </div>

      <Divider
        marginTop="24"
        marginBottom="24"
      />

      <nav className={ styles.nav }>
        <ul>
          {
            !isDesktop && (
              <>
                <li className={ styles.item }>
                  <Link
                    to="/abbrs"
                    className={ styles.link }
                  >
                    My Abbrs
                  </Link>
                </li>
                <li className={ styles.item }>
                  <Link
                    to="/profile/notifications"
                    className={ styles.link }
                  >
                    Notifications
                  </Link>
                </li>
              </>
            )
          }
          <li className={ styles.item }>
            <CookieConsent closeOnOverlayClick>
              {
                ({ openModal }) => (
                  <button
                    className={ styles.link }
                    type="button"
                    onClick={ openModal }
                    disabled={ isLoading }
                    data-location="profile"
                  >
                    Configure Cookies
                  </button>
                )
              }
            </CookieConsent>
          </li>
          <li className={ styles.item }>
            <Link
              to="/terms"
              className={ styles.link }
              data-legal="terms"
              data-location="profile"
            >
              Terms & Conditions
            </Link>
          </li>
          <li className={ styles.item }>
            <Link
              to="/privacy-policy"
              className={ styles.link }
              data-legal="privacy-policy"
              data-location="profile"
            >
              Privacy & Cookies Policy
            </Link>
          </li>
        </ul>
      </nav>

      {
        !isDesktop && (
          <Divider
            marginTop="24"
            marginBottom="24"
          />
        )
      }

      <div className={ styles.contacts }>
        <div className={ styles.contact }>
          <Heading
            className={ styles.label }
            type="p"
            size="h5"
          >
            Contact us
          </Heading>
          <a
            href="mailto:support@abbr.studio"
            className={ styles.link }
            data-location="profile"
            data-contact="support"
          >
            support@abbr.studio
          </a>
        </div>

        <div className={ styles.contact }>
          <Heading
            className={ styles.label }
            type="p"
            size="h5"
          >
            Report abuse
          </Heading>
          <a
            href="mailto:abuse@abbr.studio"
            className={ styles.link }
            data-location="profile"
            data-contact="abuse"
          >
            abuse@abbr.studio
          </a>
        </div>
      </div>

      <Divider
        marginTop="24"
        marginBottom="24"
      />

      {
        isDesktop && (
          <>
            <div className={ styles.notificationsWrapper }>
              <Heading
                type="h3"
                className={ styles.heading }
              >
                Notifications
              </Heading>
              <Notifications />
            </div>
            <Divider
              marginTop="24"
              marginBottom="24"
            />
          </>
        )
      }

      <div className={ styles.danger }>
        <Heading type="h3">Danger Zone</Heading>
        <Paragraph
          marginTop="8"
          marginBottom="16"
        >
          If you delete your profile you will lose all
          your abbrs. They will still be active, but you
          won’t be able to control and edit them.
        </Paragraph>

        <div className={ styles.action }>
          <DeleteConfirm>
            {
              ({ openModal }) => (
                <Button
                  variant="danger"
                  onClick={ openModal }
                  disabled={ isLoading }
                  block="mobile"
                >
                  Delete Profile
                </Button>
              )
            }
          </DeleteConfirm>
        </div>
      </div>
    </div>
  );
};

export default Main;
