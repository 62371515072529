import merge from 'lodash/merge';
import { createSelector } from 'reselect';

import { ABBR_DOMAIN } from 'shared/constants';

import * as actionTypes from '../actions/types';

const draftState = {
  type: 'redirect',
  url: '',
  name: '',
  domain: ABBR_DOMAIN,
  slashtag: '',
  tracking: {
    facebook: '',
    google: '',
  },
  preview: {
    title: '',
    description: '',
    image: '',
  },
  customize: [],
  isFetching: false,
};

const draft = (state = draftState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CREATED_ABBR:
      return {
        ...state,
        ...action.abbr,
        tracking: merge({}, state.tracking, action.abbr.tracking || {}),
        isFetching: false,
      };
    case actionTypes.FETCHING_SLASHTAG:
      return merge({}, state, {
        isFetching: true,
      });
    case actionTypes.RESET_CREATED_ABBR:
    case actionTypes.LOGGED_OUT_USER:
      return { ...draftState };
    default:
      return state;
  }
};

export default draft;

/*
 Selectors
 */
export const getCurrentAbbr = (state) => state.draft;
export const getShortLink = (state) => state.draft.abbr;
export const getAbbrUrl = createSelector(
  (state) => state.draft.url,
  (url) => url,
);
export const getSlashtag = (state) => state.draft.slashtag || {};
export const getTracking = (state) => state.draft.tracking || [];
