import React, { useReducer, useRef } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Icon from 'components/Icon';
import IconButton from 'components/IconButton';

import useClipboard from 'shared/helpers/hooks/useClipboard';

const INITIAL_STATE = {
  variant: 'primary',
  label: 'Copy',
  icon: 'copy',
};

const stateReducer = (initialState) => (state, action) => {
  switch (action.type) {
    case 'success':
      return {
        variant: 'success',
        label: 'Copied!',
        icon: 'check',
      };
    case 'error':
      return {
        variant: 'warning',
        label: 'Not supported!',
        icon: 'warning',
      };
    default:
      return initialState;
  }
};

const CopyButton = ({
  className,
  variant,
  permalink,
  block,
  small,
  fill,
  onCopied,
  ...restProps
}) => {
  const statusTimeout = useRef(0);
  const {
    supportsClipboard,
    copyToClipboard,
  } = useClipboard();

  const initialState = {
    ...INITIAL_STATE,
    variant,
  };

  const [ params, dispatch ] = useReducer(stateReducer(initialState), initialState);

  const updateStatus = (newStatus) => {
    clearTimeout(statusTimeout.current);

    dispatch({ type: newStatus });

    statusTimeout.current = setTimeout(() => {
      dispatch({ type: 'default' });
    }, 2000);
  };

  const handleCopy = () => {
    copyToClipboard(permalink)
      .then(() => {
        onCopied();
        updateStatus('success');
      })
      .catch(() => {
        updateStatus('error');
      });
  };

  if (!supportsClipboard) return null;

  if (small) {
    return (
      <IconButton
        className={ className }
        variant={ params.variant }
        icon={ params.icon }
        onClick={ handleCopy }
        fill={ fill }
        data-abbr="copy"
        { ...restProps }
      />
    );
  }

  return (
    <Button
      className={ className }
      variant={ params.variant }
      block={ block }
      onClick={ handleCopy }
      data-abbr="copy"
      { ...restProps }
    >
      <Icon icon={ params.icon } />
      { params.label }
    </Button>
  );
};

CopyButton.propTypes = {
  permalink: PropTypes.string.isRequired,
  variant: PropTypes.string,
  className: PropTypes.string,
  small: PropTypes.bool,
  block: PropTypes.bool,
  fill: PropTypes.bool,
  onCopied: PropTypes.func,
};

CopyButton.defaultProps = {
  className: '',
  variant: 'primary',
  small: false,
  fill: false,
  block: true,
  onCopied: () => {
  },
};

export default CopyButton;
