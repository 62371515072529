import React from 'react';
import ReactModal from 'react-modal';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';

import { useModal } from './withModal';

import styles from './Modal.module.scss';

const cn = classnames.bind(styles);

ReactModal.setAppElement('#root');

const Modal = ({
  className,
  animation,
  position,
  label,
  onOpen,
  onClose,
  closeOnOverlayClick,
  onRequestClose,
  children,
}) => {

  const {
    opening,
    closing,
    isOpen,
    closeModal,
  } = useModal();

  const [ v, h ] = position.split('-');

  const portalClassname = cn('portal',);

  const overlayClassName = cn(
    'overlay',
    isOpen && 'is-open',
    'animation-fade',
    opening && 'animation-fade-in',
    closing && 'animation-fade-exit',
    `vertically-${ v }`,
    `horizontally-${ h }`,
  );

  const contentClassName = cn(
    'content',
    isOpen && 'is-open',
    `animation-${ animation }`,
    opening && `animation-${ animation }-in`,
    closing && `animation-${ animation }-exit`,
    className,
  );

  const handleRequestClose = () => {
    if (typeof onRequestClose === 'function') {
      onRequestClose().then(closeModal);
    } else {
      closeModal();
    }
  };

  return (
    <ReactModal
      isOpen={ isOpen }
      closeTimeoutMS={ 350 }
      onRequestClose={ handleRequestClose }
      onAfterOpen={ onOpen }
      onAfterClose={ onClose }
      contentLabel={ label }
      portalClassName={ portalClassname }
      overlayClassName={ overlayClassName }
      shouldCloseOnEsc={ closeOnOverlayClick }
      shouldCloseOnOverlayClick={ closeOnOverlayClick }
      className={ contentClassName }
      bodyOpenClassName="body--modal-open"
      htmlOpenClassName="html--modal-open"
    >
      { children }
    </ReactModal>
  );
};

Modal.propTypes = {
  className: PropTypes.string,
  animation: PropTypes.oneOf([ 'fade', 'slide-bottom', 'slide-right', 'slide-top' ]),
  position: PropTypes.oneOf([ 'center-center', 'center-left', 'center-right', 'bottom-center', 'bottom-left', 'bottom-right', 'top-center', 'top-left', 'top-right' ]),
  label: PropTypes.string.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onRequestClose: PropTypes.func,
  closeOnOverlayClick: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Modal.defaultProps = {
  className: '',
  animation: 'fade',
  position: 'center-center',
  onOpen: null,
  onClose: null,
  closeOnOverlayClick: true,
  onRequestClose: null,
};

export default Modal;
