import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import createStore from './createStore';

const withStore = (NextComponent) => {
  const { store, persistor } = createStore();

  return function StoreProvider(props) {
    return (
      <Provider store={ store }>
        <PersistGate persistor={ persistor }>
          <NextComponent { ...props } />
        </PersistGate>
      </Provider>
    );
  };
};

export default withStore;