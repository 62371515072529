import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import { useLoader } from 'components/Loader';
import Modal, { useModal, withModal } from 'components/Modal';
import { Heading } from 'components/Typography';

import { useMedia } from 'shared/helpers/hocs/withMedia';
import useAction from 'shared/helpers/hooks/useAction';

import styles from './DeleteConfirm.module.scss';

const DeleteConfirm = ({ children }) => {
  const history = useHistory();
  const {
    openModal,
    closeModal,
  } = useModal();
  const [ isLoading, setLoading ] = useLoader();
  const {
    deleteUser
  } = useAction();
  const { isDesktop } = useMedia();

  const handleDeleteAccount = () => {
    setLoading(true);

    deleteUser()
      .finally(() => setLoading(false));

    history.push('/');
  };

  return (
    <>
      { children({ openModal }) }
      <Modal
        animation={ !isDesktop ? 'slide-bottom' : 'slide-top' }
        position={ !isDesktop ? 'bottom-center' : 'top-center' }
        label="Share Link"
      >
        <div className={ styles.content }>
          <Heading
            size="h4"
          >
            Are you sure want to&nbsp;delete&nbsp;profile?
          </Heading>

          <div className={ styles.actions }>
            <div className={ styles.action }>
              <Button
                variant="danger"
                onClick={ handleDeleteAccount }
                disabled={ isLoading }
                block
                data-profile="delete"
              >
                Delete
              </Button>
            </div>
            <div className={ styles.action }>
              <Button
                variant="secondary"
                onClick={ closeModal }
                disabled={ isLoading }
                block
              >
                No, I want to stay
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

DeleteConfirm.propTypes = {
  children: PropTypes.func.isRequired,
};

export default withModal(DeleteConfirm);
