import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import AllowCookiesDialog from 'components/CookieConsent/AllowCookiesDialog';
import Icon from 'components/Icon';
import { useLoader } from 'components/Loader';

import { getCookies } from 'store/reducers/cookies';
import navigateExternal from 'shared/helpers/functions/navigateExternal';

import { AUTH_URL } from 'shared/constants';

const LoginButton = ({
  variant,
  renderButton,
  className,
}) => {
  const cookies = useSelector(getCookies);
  const [ isLoading, setLoading ] = useLoader();
  const redirectTimeout = useRef(0);

  const onCookieAllowed = () => {
    setLoading(true);

    redirectTimeout.current = setTimeout(() => {
      navigateExternal(AUTH_URL);
    }, 1000);
  };

  useEffect(() => {
    clearTimeout(redirectTimeout.current);
  }, []);

  if (cookies.auth) {
    return renderButton({
      className,
      variant,
      href: AUTH_URL,
      disabled: isLoading,
    });
  }

  return (
    <AllowCookiesDialog
      cookie="auth"
      message="You need to allow authorization cookies to continue"
      onCookieAllowed={ onCookieAllowed }
    >
      {
        ({ openModal }) => renderButton({
          className,
          variant,
          onClick: openModal,
          disabled: isLoading,
        })
      }
    </AllowCookiesDialog>
  );
};

LoginButton.propTypes = {
  className: PropTypes.string,
  renderButton: PropTypes.func,
  variant: PropTypes.oneOf([ 'primary', 'secondary', 'ghost' ]),
};

LoginButton.defaultProps = {
  className: '',
  variant: 'ghost',
  renderButton: (props) => (
    <Button { ...props }>
      Log in
      <Icon icon="login" />
    </Button>
  ),

};

export default LoginButton;
