import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import abbrs from './abbrs';
import cookies from './cookies';
import draft from './draft';
import errors from './errors';
import user from './user';

const userReducer = persistReducer({
  key: 'user',
  blacklist: [ 'events' ],
  storage,
}, user);

const draftAbbrReducer = persistReducer({
  key: 'draft',
  storage,
}, draft);

const cookiesReducer = persistReducer({
  key: 'cookies',
  storage,
}, cookies);

const rootReducer = combineReducers({
  user: userReducer,
  draft: draftAbbrReducer,
  abbrs,
  cookies: cookiesReducer,
  errors,
});

export default rootReducer;
