import React, { useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';

import Editable from 'components/Editable';
import Icon from 'components/Icon';
import { Caption } from 'components/Typography';

import validateSlashtag from 'shared/helpers/functions/validateSlashtag';
import useAction from 'shared/helpers/hooks/useAction';
import useDebounce from 'shared/helpers/hooks/useDebounce';

import IconButton from '../IconButton';

import styles from './SlashtagField.module.scss';

const variants = {
  initial: {
    rotate: 180,
    scale: 0,
    opacity: 0,
  },
  show: {
    rotate: 0,
    scale: 1,
    opacity: 1,
  },
  exit: {
    rotate: -180,
    scale: 0,
    opacity: 0,
  },
};

const validationIconMap = {
  'valid': 'check',
  'invalid': 'cross',
  'fetching': 'loading',
};

const SlashtagField = ({
  inputRef,
  value,
  updated,
  onChange,
  onValid,
  onError,
}) => {
  const backupValue = useRef(value);
  const lastValidValue = useRef(value);
  const statusTimeout = useRef(0);
  const updatedCounter = useRef(Number(updated));
  const [ status, setStatus ] = useState(null);
  const [ slashtag, setSlashtag ] = useState(value);
  const [ valid, setValid ] = useState({
    length: 'valid',
    free: 'valid',
  });
  const {
    checkSlashtag,
    trackEvent,
  } = useAction();

  const debouncedSlashtag = useDebounce(slashtag, 300);

  const restrictSymbols = (e) => {
    const regExp = /^[0-9A-Za-z._]+$/;

    if (!regExp.test(e.key)) {
      e.preventDefault();
    }
  };

  const checkSlashtagValidity = () => {
    return validateSlashtag(debouncedSlashtag)
      .then(() => {
        setValid({
          length: 'valid',
          free: 'fetching',
        });

        return Promise.resolve();
      })
      .then(() => {
        if (debouncedSlashtag === lastValidValue.current) {
          return Promise.resolve();
        }

        return checkSlashtag(debouncedSlashtag);
      })
      .then(() => {
        setValid({
          length: 'valid',
          free: 'valid',
        });

        onValid();

        return Promise.resolve();
      })
      .catch((e) => {
        setValid({
          free: e.type !== 'SLASHTAG_TAKEN' ? 'valid' : 'invalid',
          length: e !== 'SLASHTAG_SHORT' && e !== 'SLASHTAG_LONG' ? 'valid' : 'invalid',
        });

        onError();

        return Promise.reject();
      });
  };

  useEffect(() => {
    checkSlashtagValidity();
  }, [ debouncedSlashtag ]);

  useEffect(() => {
    !slashtag && setSlashtag(value);

    if (!lastValidValue.current) {
      lastValidValue.current = value;
    }

    if (!backupValue.current) {
      backupValue.current = value;
    }

    setValid({
      length: 'valid',
      free: 'valid',
    });
  }, [ value ]);

  useEffect(() => {
    return () => {
      clearTimeout(statusTimeout.current);
    };
  }, []);

  const updateStatus = (newStatus, delay = 2000) => {
    clearTimeout(statusTimeout.current);

    return new Promise((resolve) => {

      setStatus(newStatus);

      if (delay) {
        statusTimeout.current = setTimeout(() => {
          setStatus(null);

          resolve();
        }, delay);
      }
    });
  };

  const handleBlur = () => {
    return checkSlashtagValidity()
      .then(() => {
        if (lastValidValue.current === slashtag) return;

        updatedCounter.current++;

        onChange({
          slashtag,
          customizeData: slashtag !== backupValue.current ? {
            abbr: true,
          } : {
            abbr: updatedCounter.current > 0,
          },
        });

        trackEvent('ABBR/SLASHTAG');

        backupValue.current = lastValidValue.current;
        lastValidValue.current = slashtag;
        updateStatus('updated')
          .then(() => updateStatus('canRevert', null));
      })
      .catch(() => {
        updateStatus('error');
        setSlashtag(lastValidValue.current);
      });
  };

  const revertValue = () => {
    updateStatus(null);

    setTimeout(() => {
      setSlashtag(backupValue.current);

      onChange({
        slashtag: backupValue.current,
        customizeData: {
          abbr: updatedCounter.current > 1,
        },
      });

      updatedCounter.current--;
      lastValidValue.current = backupValue.current;
    }, 300);
  };

  const tooltip = (
    <div className={ styles.tooltip }>
      <Caption className={ styles.caption }>
        Changing the slashtag will deactivate current one.
        <br />
        Analytics will be saved and continue to be collected.
      </Caption>

      <Caption className={ styles[valid.free] }>
        <Icon
          icon={ validationIconMap[valid.free] }
        />
        { valid.free === 'fetching' ? 'checking slashtag' : 'slashtag free' }
      </Caption>

      <Caption className={ styles[valid.length] }>
        <Icon
          icon={ validationIconMap[valid.length] }
        />
        from 5 to 20 characters
      </Caption>
    </div>
  );

  const adornment = (
    <AnimatePresence exitBeforeEnter>
      {
        status === 'updated' && (
          <motion.div
            className={ styles[status] }
            key="updated"
            variants={ variants }
            initial="initial"
            exit="exit"
            animate="show"
          >
            <Icon
              icon="refresh"
              size="12"
            />
          </motion.div>
        )
      }

      {
        status === 'canRevert' && (
          <motion.div
            className={ styles[status] }
            key="canRevert"
            variants={ variants }
            initial="initial"
            exit="exit"
            animate="show"
          >
            <IconButton
              icon="repeat"
              variant="secondary"
              size="12"
              onClick={ revertValue }
              data-revert="slashtag"
            />
          </motion.div>
        )
      }

      {
        status === 'error' && (
          <motion.div
            className={ styles[status] }
            key="error"
            variants={ variants }
            initial="initial"
            exit="exit"
            animate="show"
          >
            <Icon
              icon="cross"
              size="16"
            />
          </motion.div>
        )
      }
    </AnimatePresence>
  );

  return (
    <Editable
      inputRef={ inputRef }
      value={ slashtag }
      placeholder={ slashtag }
      onChange={ setSlashtag }
      onBlur={ handleBlur }
      onKeyPress={ restrictSymbols }
      tooltip={ tooltip }
      adornment={ adornment }
      error={ valid.length !== 'valid' || valid.free === 'invalid' }
    />
  );
};

SlashtagField.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  value: PropTypes.string.isRequired,
  updated: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onValid: PropTypes.func,
  onError: PropTypes.func,
};

SlashtagField.defaultProps = {
  inputRef: undefined,
  updated: false,
  onValid: () => {
  },
  onError: () => {
  },
};

export default SlashtagField;
