import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';

import useLoader from './loaderContext';

import styles from './Loader.module.scss';

const duration = 1.2;

const topLoader = {
  visible: {
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.8 * duration,
    },
  },
  hidden: {
    transition: {
      duration: 0,
      when: 'afterChildren',
    },
  },
};

const item = {
  visible: {
    x: [ '-100%', '100%' ],
    transition: {
      duration,
    },
  },
  hidden: {
    x: [ '100%', '-100%' ],
    transition: {
      duration: 0,
    },
  },
};

const Loader = () => {
  const [ isLoading ] = useLoader();
  const controls = useAnimation();

  const [ active, setActive ] = useState(isLoading);

  useEffect(() => {
    setActive(isLoading);
  }, [ controls, isLoading ]);

  useEffect(() => {
    controls.start(active ? 'visible' : 'hidden');
  }, [ active, controls ]);

  const handleAnimationComplete = () => isLoading
    ? controls.start('visible')
    : setActive(false);

  return active ? (
    <motion.div
      key="loader"
      className={ styles.topLoader }
      initial="hidden"
      animate={ controls }
      onAnimationComplete={ handleAnimationComplete }
      variants={ topLoader }
    >
      <motion.div
        className={ styles.item }
        variants={ item }
      />
      <motion.div
        className={ styles.item }
        variants={ item }
      />
    </motion.div>
  ) : null;
};

export default Loader;