import React from 'react';
import { Link } from 'react-router-dom';

import Container from 'components/Container';
import CookieConsent from 'components/CookieConsent';
import Divider from 'components/Divider';
import Logo from 'components/Logo';
import { Heading, Paragraph } from 'components/Typography';

import LoginButton from '../LoginButton';

import styles from './Menu.module.scss';

const Menu = () => {
  return (
    <div className={ styles.menu }>
      <Container>
        <header className={ styles.header }>
          <Logo
            size="sm"
            variant="invert"
            to="/"
          />
        </header>
        <div className={ styles.content }>
          <Heading type="h3">Log in to Abbr Studio</Heading>

          <Paragraph
            marginTop="8"
            marginBottom="16"
          >
            To be able to view and edit your abbrs,
            analyze statistics and add tracking.
          </Paragraph>

          <LoginButton variant="primary" />

          <Divider
            marginTop="24"
            marginBottom="24"
          />

          <nav>
            <ul>
              <li className={ styles.item }>
                <CookieConsent closeOnOverlayClick>
                  {
                    ({ openModal }) => (
                      <button
                        className={ styles.link }
                        type="button"
                        onClick={ openModal }
                      >
                        Configure Cookies
                      </button>
                    )
                  }
                </CookieConsent>
              </li>
              <li className={ styles.item }>
                <Link
                  to="/terms"
                  className={ styles.link }
                >
                  Terms & Conditions
                </Link>
              </li>
              <li className={ styles.item }>
                <Link
                  to="/privacy-policy"
                  className={ styles.link }
                >
                  Privacy & Cookies Policy
                </Link>
              </li>
            </ul>
          </nav>

          <Divider
            marginTop="24"
            marginBottom="24"
          />

          <div className={ styles.contact }>
            <Heading
              className={ styles.label }
              type="p"
              size="h5"
            >
              Contact us
            </Heading>
            <a
              href="mailto:support@abbr.studio"
              className={ styles.link }
            >
              support@abbr.studio
            </a>
          </div>

          <div className={ styles.contact }>
            <Heading
              className={ styles.label }
              type="p"
              size="h5"
            >
              Report abuse
            </Heading>
            <a
              href="mailto:abuse@abbr.studio"
              className={ styles.link }
            >
              abuse@abbr.studio
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Menu;
