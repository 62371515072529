import ENDPOINTS from './api/config';

const isProduction = process.env.REACT_APP_ABBR_ENV === 'production';

export const ABBR_DOMAIN = isProduction ? 'abbr.ly' : 'test.abbr.ly';
export const AUTH_URL = ENDPOINTS.url.main;
export const TAG_MANAGER_ID = isProduction ? 'GTM-5BT9THB' : 'GTM-MZH982Z';
export const GA_TRACKING_ID = isProduction ? 'G-P4WXX39Z4V' : 'G-C5KKLEM2LN';
export const METRIKA_TRACKING_ID = isProduction ? '74219881' : '72583036';
export const SENTRY_DSN = 'https://dc8486f5e40644ce9baa010c5c3e0056@o392563.ingest.sentry.io/5240268';

export const CONTACTS = [
  {
    label: 'Contact Us',
    email: 'contact@abbr.studio',
  },
  {
    label: 'For partners',
    email: 'partners@abbr.studio',
  },
  {
    label: 'Report abuse',
    email: 'report@abbr.studio',
  },
];
