import React from 'react';
import PropTypes from 'prop-types';

import Modal, { useModal, withModal } from 'components/Modal';

import { useMedia } from 'shared/helpers/hocs/withMedia';

import QRcode from './QRcode';

import styles from './QRcodeModal.module.scss';

const QRcodeModal = ({
  permalink,
  children,
}) => {
  const {
    isOpen,
    closing,
    openModal,
  } = useModal();
  const { isDesktop } = useMedia();

  return (
    <>
      {
        children({
          isOpen,
          openModal,
        })
      }
      {
        (isOpen || closing) && (
          <Modal
            animation={ !isDesktop ? 'slide-bottom' : 'slide-right' }
            position={ !isDesktop ? 'bottom-center' : 'center-right' }
            label="Cookie Consent"
          >
            <div className={ styles.modal } data-qrcode="visible">
              <QRcode permalink={ permalink } />
            </div>
          </Modal>
        )
      }
    </>
  );
};

QRcodeModal.propTypes = {
  permalink: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
};

export default withModal(QRcodeModal);
