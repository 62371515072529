import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import withLoader from 'components/Loader';

import { useMedia } from 'shared/helpers/hocs/withMedia';

import Complete from './screens/Complete';
import Start from './screens/Start';

const Create = () => {
  const { path } = useRouteMatch();
  const { isDesktop } = useMedia();

  return (
    <Switch>
      {
        !isDesktop && (
          <Route
            path={ `${ path }/:type/:id` }
            exact
          >
            <Complete />
          </Route>
        )
      }
      <Route
        path={ `${ path }/:type` }
        exact
      >
        <Start />
      </Route>
      <Route
        path={ path }
        exact
      >
        <Redirect to={ `${ path }/short-link` } />
      </Route>
    </Switch>
  );
};

export default withLoader(Create);
