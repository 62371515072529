import React from 'react';
import PropTypes from 'prop-types';

import { Heading } from 'components/Typography';

import styles from './RedirectPage.module.scss';

const RedirectPage = ({ text }) => {
  return (
    <div className={ styles.page }>
      <div className={ styles.screen }>
        <Heading
          type="h4"
          align="center"
          size="l"
          marginTop="l"
        >
          { text }
        </Heading>
      </div>
    </div>
  );
};

RedirectPage.propTypes = {
  text: PropTypes.string,
};

RedirectPage.defaultProps = {
  text: 'Redirecting...',
};

export default RedirectPage;
