import React from 'react';
import PropTypes from 'prop-types';

import Modal, { useModal, withModal } from 'components/Modal';

import { useMedia } from 'shared/helpers/hocs/withMedia';

import DomainConfigurator from './DomainConfigurator';

import styles from './DomainModal.module.scss';

const DomainModal = ({
  children,
}) => {
  const {
    isOpen,
    closing,
    openModal,
  } = useModal();
  const { isDesktop } = useMedia();

  return (
    <>
      {
        children({
          isOpen,
          openModal,
        })
      }
      {
        (isOpen || closing) && (
          <Modal
            animation={ !isDesktop ? 'slide-bottom' : 'slide-right' }
            position={ !isDesktop ? 'bottom-center' : 'center-right' }
            label="Domain customization"
          >
            <div className={ styles.modal } data-domain="visible">
              <DomainConfigurator />
            </div>
          </Modal>
        )
      }
    </>
  );
};

DomainModal.propTypes = {
  children: PropTypes.func.isRequired,
};

export default withModal(DomainModal);
