import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import IconButton from 'components/IconButton';
import ShareButton from 'components/ShareButton';

import { useMedia } from 'shared/helpers/hocs/withMedia';

import styles from './Share.module.scss';

const getShareUrl = (id, srcUrl, title) => {
  switch (id) {
    case 'facebook':
      return `https://www.facebook.com/dialog/feed?app_id=179569853382104&display=page&link=${ srcUrl }`;
    case 'twitter':
      return `http://twitter.com/share?text=${ title }&url=${ srcUrl }&hashtags=abbr`;
    default:
      return srcUrl;
  }
};

const Share = ({
  title,
  text,
  url,
  buttonProps,
  onShare,
}) => {
  const encodedTitle = encodeURIComponent(title || 'Link abbred by Abbr Studio');
  // const encodedText = encodeURIComponent(text);

  const [ services, setServices ] = useState([
    {
      id: 'facebook',
      url: getShareUrl('facebook', url),
    },
    {
      id: 'twitter',
      url: getShareUrl('twitter', url, encodedTitle),
    },
  ]);
  const { isDesktop } = useMedia();

  useEffect(() => {
    setServices([
      {
        id: 'facebook',
        url: getShareUrl('facebook', url),
      },
      {
        id: 'twitter',
        url: getShareUrl('twitter', url, encodedTitle),
      },
    ]);
  }, [ title ]);

  const handleShare = (id) => () => onShare(id);

  const renderServices = services.map((service) => (
    <li
      key={ service.id }
      className={ styles.service }
    >
      <IconButton
        variant="secondary"
        icon={ service.id }
        shape="square"
        target="_blank"
        rel="noreferrer noopener"
        href={ service.url }
        onClick={ handleShare(service.id) }
        data-abbr="share"
        data-share={ service.id }
        data-location="abbr_details"
      />
    </li>
  ));

  return isDesktop ? (
    <ul className={ styles.share }>
      { renderServices }
    </ul>
  ) : (
    <ShareButton
      title={ title }
      text={ text }
      url={ url }
      onShare={ onShare }
      { ...buttonProps }
    />
  );
};

Share.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  onShare: PropTypes.func,
  buttonProps: PropTypes.shape({
    className: PropTypes.string,
    small: PropTypes.bool,
    block: PropTypes.oneOf([ 'mobile', 'desktop', true, false ]),
  }),
};

Share.defaultProps = {
  title: 'Link abbred by Abbr Studio',
  text: '',
  onShare: () => {
  },
  buttonProps: {
    className: '',
    small: false,
    block: true,
  },
};

export default Share;
