/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';

import LoginButton from '../LoginButton';

import styles from './SlashtagField.module.scss';

const SlashtagPlaceholder = ({ value }) => {
  const tooltipId = useRef(uniqueId());
  const reactTooltipRef = useRef();

  return (
    <>
      <span
        className={ styles.placeholder }
        tabIndex="0"
        data-tip
        data-for={ tooltipId.current }
        data-event="focus"
        data-event-off="blur"
      >
        { value }
      </span>
      <ReactTooltip
        ref={ reactTooltipRef }
        className={ styles.tooltipThin }
        id={ tooltipId.current }
        place="bottom"
        effect="solid"
        delayUpdate={ 500 }
        afterShow={
          () => {
            const { tooltipRef } = reactTooltipRef.current;

            if (!tooltipRef) return;

            const rect = tooltipRef.getBoundingClientRect();

            const overflownLeft = rect.left < 0;
            const overflownRight = rect.right > window.innerWidth;

            if (overflownLeft) {
              tooltipRef.style.setProperty('left', '10px');
              tooltipRef.style.setProperty('right', 'auto');
            } else if (overflownRight) {
              tooltipRef.style.setProperty('left', 'auto');
              tooltipRef.style.setProperty('right', '10px');
            }
          }
        }
      >
        Log in to be able to customize slashtag.
        <LoginButton
          className={ styles.login }
          renderButton={ (props) => <a { ...props }>Log In</a> }
        />
      </ReactTooltip>
    </>
  );
};

SlashtagPlaceholder.propTypes = {
  value: PropTypes.string.isRequired,
};

export default SlashtagPlaceholder;
