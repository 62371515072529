import React from 'react';
import PropTypes from 'prop-types';

import Container from 'components/Container';

import styles from './Article.module.scss';

const Article = ({ children }) => {
  return (
    <Container
      className={ styles.article }
      narrow
    >
      { children }
    </Container>
  );
};

Article.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Article;
