const errorMessage = {
  SLASHTAG_SHORT: 'Slashtag should be at least 5 symbols length',
  SLASHTAG_LONG: 'Slashtag should be no longer than 10 symbols',
  SLASHTAG_TAKEN: 'Slashtag already taken',
  URL_INVALID: 'Invalid URL',
  CRITICAL_DUPLICATE_ABBR: 'Abbr already exist',
  CRITICAL_NETWORK_ERROR: 'Server does not respond. Please check your internet connection.',
};

const getErrorMessage = (type) => errorMessage[type] || 'Something went wrong.';

export default getErrorMessage;