/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import { useLoader } from 'components/Loader';
import { Caption } from 'components/Typography';

import { getCurrentAbbr } from 'store/reducers/draft';
import { getUser } from 'store/reducers/user';
import { useMedia } from 'shared/helpers/hocs/withMedia';
import useAction from 'shared/helpers/hooks/useAction';

import UrlField from './components/UrlField';

import styles from './ShortLink.module.scss';

const ShortLink = ({ error }) => {
  const tooltipRef = useRef();
  const tooltipId = useRef(uniqueId());
  const tooltipTimeout = useRef(0);
  const [ isLoading, setLoading ] = useLoader();
  const { url } = useRouteMatch();
  const history = useHistory();
  const { isDesktop } = useMedia();
  const {
    fetchPreview,
    createAbbr,
    resetCreatedAbbr,
    updateCreatedAbbr,
  } = useAction();
  const { isLoggedIn } = useSelector(getUser);
  const abbr = useSelector(getCurrentAbbr);
  const prevUrl = useRef(abbr.url);
  const [ isNextStepAllowed, setNextStepAllowed ] = useState(false);
  const [ urlError, setUrlError ] = useState(null);

  useEffect(() => {
    if (abbr.url && prevUrl.current !== abbr.url && !abbr.customize.includes('name')) {
      setLoading(true);

      prevUrl.current = abbr.url;

      fetchPreview(null, abbr.url)
        .catch(() => {
          updateCreatedAbbr({
            preview: {},
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [ abbr.url ]);

  const saveAbbr = () => {
    ReactTooltip.hide(tooltipRef?.current);
    setLoading(true);

    createAbbr(abbr)
      .then((res) => {
        resetCreatedAbbr();
        setLoading(false);

        history.push(isDesktop ? `/abbrs/${ res.id }` : `${ url }/${ res.id }`);

        return Promise.resolve(res);
      })
      .catch((e) => {
        if (e.type === 'POTENTIALLY_MALICIOUS_URL') {
          setUrlError('This URL is potentially malicious, we cannot accept it for security reasons.');
        }

        ReactTooltip.show(tooltipRef?.current);

        tooltipTimeout.current = setTimeout(() => {
          ReactTooltip.hide(tooltipRef?.current);
        }, 2000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    return () => {
      clearTimeout(tooltipTimeout.current);
    };
  }, []);

  useEffect(() => {
    setNextStepAllowed(abbr.url && abbr.slashtag && !error && !isLoading);
  }, [ abbr, error, isLoading ]);

  return (
    <div className={ styles.screen }>
      <div className={ styles.field }>
        <UrlField
          error={ urlError }
          onError={ setUrlError }
        />
      </div>

      {
        !isLoggedIn && (
          <Caption className={ styles.consent }>
            By clicking “Create” you’re agreeing to the
            { ' ' }
            <Link
              to="/privacy-policy"
              target="_blank"
              data-legal="privacy-policy"
              data-location="abbr_creation"
            >
              Privacy Policy
            </Link>
            { ' ' }
            and
            { ' ' }
            <Link
              to="/terms"
              target="_blank"
              data-legal="terms"
              data-location="abbr_creation"
            >
              Terms & Conditions
            </Link>
          </Caption>
        )
      }

      <div className={ styles.button }>
        <Button
          buttonRef={ tooltipRef }
          disabled={ !isNextStepAllowed }
          onClick={ saveAbbr }
          data-create="short_link"
          data-tip="error"
          data-for={ tooltipId.current }
          data-event="none"
          data-event-off="click"
          data-global-event-off="click"
          block
        >
          Create
        </Button>
        <ReactTooltip
          className={ styles.tooltip }
          id={ tooltipId.current }
          place="top"
          effect="solid"
        >
          There was an error. Please try again!
        </ReactTooltip>
      </div>
    </div>
  );
};

ShortLink.propTypes = {
  error: PropTypes.bool,
};

ShortLink.defaultProps = {
  error: false,
};

export default ShortLink;
