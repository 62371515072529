import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actionCreators from 'store/actions';

const useAction = () => {
  const dispatch = useDispatch();

  return bindActionCreators(actionCreators, dispatch);
};

export default useAction;