import * as actionTypes from '../actions/types';

const errors = (state = [], action) => {
  switch (action.type) {
    case actionTypes.ADD_ERROR:
      return [
        ...state,
        ...action.errors,
      ];
    case actionTypes.REMOVE_ERROR:
      return state.filter((error) => action.error.type !== error.type);
    case actionTypes.RESET_ERRORS:
    case actionTypes.LOGGED_OUT_USER:
      return [];
    default:
      return state;
  }
};

export default errors;

/*
  Selectors
 */
export const getAbbrUrlError = (state) => state.errors.find((error) => error.type.startsWith('URL_')) || null;
export const getSlashtagError = (state) => state.errors.find((error) => error.type.startsWith('SLASHTAG_')) || null;
export const getCriticalErrors = (state) => state.errors.filter((error) => typeof error.type === 'string' && error.type.startsWith('CRITICAL_')) || [];