import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';

import styles from './Checkbox.module.scss';

const Checkbox = ({
  name,
  value,
  checked,
  disabled,
  required,
  caption,
  onChange,
  children,
}) => {
  return (
    <label className={ styles.checkbox }>
      <input
        type="checkbox"
        className={ styles.input }
        name={ name }
        value={ value }
        checked={ checked }
        required={ required }
        disabled={ disabled }
        onChange={ onChange }
      />
      <span className={ styles.check }>
        <Icon
          className={ styles.checkIcon }
          icon={ checked ? 'box-checked' : 'box' }
          size="20"
        />
      </span>
      <span className={ styles.label }>
        { children }

        <span className={ styles.caption }>{ caption }</span>
      </span>
    </label>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  caption: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

Checkbox.defaultProps = {
  value: undefined,
  checked: undefined,
  disabled: undefined,
  caption: null,
  required: undefined,
};

export default Checkbox;
