import { useEffect, useRef } from 'react';

const useFirstMount = (callback) => {
  const firstMount = useRef(true);

  useEffect(() => {
    if (firstMount.current) {
      firstMount.current = false;

      callback();
    }
  });
};

export default useFirstMount;