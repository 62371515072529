import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';

import useFitForScreen from 'shared/helpers/hooks/useFitForScreen';

import styles from './Typography.module.scss';

const cn = classnames.bind(styles);

const sizeMap = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  p: 'h4',
};

const Heading = ({
  className,
  variant,
  type,
  size,
  align,
  marginTop,
  marginBottom,
  children,
}) => {
  const Component = type;

  const currentSize = useFitForScreen(size);
  const currentMarginTop = useFitForScreen(marginTop);
  const currentMarginBottom = useFitForScreen(marginBottom);

  const headingClassname = cn(
    `heading-${ currentSize || sizeMap[type] }`,
    `align-${ align }`,
    marginTop && `mt-${ currentMarginTop }`,
    marginBottom && `mb-${ currentMarginBottom }`,
    variant && `variant-${ variant }`,
    className,
  );

  return (
    <Component className={ headingClassname }>
      { children }
    </Component>
  );
};

Heading.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf([ 'primary', 'secondary', 'success', 'warning', 'danger' ]),
  type: PropTypes.oneOf([ 'h1', 'h2', 'h3', 'h4', 'h5', 'p' ]),
  align: PropTypes.oneOf([ 'left', 'right', 'center' ]),
  size: PropTypes.oneOfType([
    PropTypes.oneOf([ 'h1', 'h2', 'h3', 'h4', 'h5' ]),
    PropTypes.arrayOf(PropTypes.oneOf([ 'h1', 'h2', 'h3', 'h4', 'h5' ])),
  ]),
  marginTop: PropTypes.oneOfType([
    PropTypes.oneOf([ null, '4', '8', '16', '24', '32', '40', '80' ]),
    PropTypes.arrayOf(PropTypes.oneOf([ null, '4', '8', '16', '24', '32', '40', '80' ])),
  ]),
  marginBottom: PropTypes.oneOfType([
    PropTypes.oneOf([ null, '4', '8', '16', '24', '32', '40', '80' ]),
    PropTypes.arrayOf(PropTypes.oneOf([ null, '4', '8', '16', '24', '32', '40', '80' ])),
  ]),
  children: PropTypes.node,
};

Heading.defaultProps = {
  className: '',
  variant: null,
  type: 'p',
  size: null,
  align: 'left',
  marginTop: null,
  marginBottom: null,
  children: null,
};

export default Heading;
