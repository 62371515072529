import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Divider from 'components/Divider';
import { useLoader } from 'components/Loader';
import { Heading, Paragraph } from 'components/Typography';

import { getAbbrBySlashtag } from 'store/reducers/abbrs';
import { getSubscriptionStatus } from 'store/reducers/user';
import useAction from 'shared/helpers/hooks/useAction';

import Icon from '../Icon';

import styles from './PreviewModal.module.scss';

const fade = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

const Preview = ({ abbrSlashtag }) => {
  const [ isLoading, setLoading ] = useLoader();
  const {
    id,
    url,
    preview = {},
  } = useSelector(getAbbrBySlashtag(abbrSlashtag));
  const subscriptions = useSelector(getSubscriptionStatus('features'));
  const subscribed = subscriptions.includes('preview');

  const {
    subscribeUser,
    fetchPreview,
  } = useAction();

  const imageRef = useRef();
  const [ status, setStatus ] = useState(preview.title ? null : 'fetching');

  const updatePreview = () => {
    setStatus('fetching');

    fetchPreview(id, url)
      .then((res) => {
        if (res?.abbr?.preview?.image && res?.abbr?.preview?.title) {
          setStatus(null);
        } else {
          setStatus('error');
        }
      })
      .catch(() => {
        setStatus('error');
      });
  };

  const handleImageError = () => setStatus('image-error');

  const handleButtonClick = () => {
    updatePreview();
  };

  const handleSubscription = () => {
    setLoading(true);

    const subscriptionTimeout = setTimeout(() => {
      setLoading(false);

      subscribeUser({
        features: [ ...subscriptions, 'preview' ],
      });
    }, 1000);

    return () => {
      clearTimeout(subscriptionTimeout);
    };
  };

  useEffect(() => {
    if (url && !preview.title) {
      updatePreview();
    }
  }, []);

  return (
    <>
      <Heading
        type="h4"
        size={ [ 'h4', 'h3' ] }
        marginBottom="8"
      >
        Social Share Preview
      </Heading>

      {
        status === 'fetching' && (
          <>
            <div className={ styles.wrapper }>
              <div className={ styles.placeholderImage }>
                <Icon
                  icon="loading"
                  size="32"
                />
              </div>
            </div>

            <Heading
              type="h4"
              marginTop="8"
              className={ styles.title }
            >
              <span className={ styles.placeholder } />
            </Heading>
            <Paragraph
              marginTop="4"
              className={ styles.description }
            >
              <span className={ styles.placeholder } />
              <span className={ styles.placeholder } />
              <span className={ styles.placeholder } />
              <span className={ styles.placeholder } />
            </Paragraph>
          </>
        )
      }

      {
        (status === 'image-error' || status === null) && (
          <>
            <div className={ styles.wrapper }>
              {
                status === 'image-error' ? (
                  <Icon
                    className={ styles.noimg }
                    icon="image"
                    size="auto"
                  />
                ) : (
                  <img
                    ref={ imageRef }
                    className={ styles.img }
                    src={ preview.image }
                    alt={ preview.title }
                    draggable={ false }
                    onError={ handleImageError }
                  />
                )
              }
            </div>
            <Heading
              type="h4"
              marginTop="8"
              className={ styles.title }
            >
              { preview.title }
            </Heading>
            <Paragraph
              marginTop="4"
              className={ styles.description }
            >
              { preview.description }
            </Paragraph>
          </>
        )
      }

      {
        status === 'error' && (
          <div data-preview="error">
            <Paragraph
              marginTop="16"
              marginBottom="16"
            >
              We couldn`t find preview.
              <br />
              But we will try again, just tell.
            </Paragraph>

            <Button
              variant="secondary"
              onClick={ handleButtonClick }
              block
              data-preview="try_again"
            >
              Try again
            </Button>
          </div>
        )
      }

      {
        status !== 'error' && (
          <>
            <Divider />


            <div className={ styles.subscription }>
              <Heading type="h4">
                <AnimatePresence
                  initial={ false }
                  exitBeforeEnter
                >
                  {
                    subscribed
                    // eslint-disable-next-line jsx-a11y/accessible-emoji
                      ? <motion.span key="1" { ...fade }>Wait for the sign from up above 😏</motion.span>
                      : <motion.span key="2" { ...fade }>Editing will be available soon</motion.span>
                  }
                </AnimatePresence>
              </Heading>

              <Paragraph
                marginTop="4"
              >
                <AnimatePresence
                  initial={ false }
                  exitBeforeEnter
                >
                  {
                    subscribed
                      ? (
                        <motion.span key="3" { ...fade }>
                          You successfully subscribed for the news about preview editing feature release
                        </motion.span>
                      )
                      : <motion.span key="4" { ...fade }>Subscribe to notifications to be the first who knows about this cool feature</motion.span>
                  }
                </AnimatePresence>
              </Paragraph>

              <div className={ styles.loader }>
                {
                  (isLoading || subscribed) && (
                    <Icon
                      initialTransition
                      icon={ subscribed ? 'check' : 'loading' }
                    />
                  )
                }
              </div>


              {
                !subscribed && (
                  <div
                    className={ styles.action }
                  >
                    <Button
                      variant="secondary"
                      onClick={ handleSubscription }
                      disabled={ isLoading }
                      data-subscribe="preview"
                      block
                    >
                      Subscribe
                    </Button>
                  </div>
                )
              }
            </div>
          </>
        )
      }
    </>
  );
};

Preview.propTypes =
  {
    abbrSlashtag: PropTypes.string.isRequired,
  };

export default Preview;
