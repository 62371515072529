import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';

import useFitForScreen from 'shared/helpers/hooks/useFitForScreen';

import styles from './Typography.module.scss';

const cn = classnames.bind(styles);

const Caption = ({
  className,
  type,
  variant,
  align,
  marginTop,
  marginBottom,
  children,
}) => {
  const Component = type;

  const currentMarginTop = useFitForScreen(marginTop);
  const currentMarginBottom = useFitForScreen(marginBottom);

  const captionClassname = cn(
    'caption',
    `align-${ align }`,
    `mt-${ currentMarginTop }`,
    `mb-${ currentMarginBottom }`,
    variant && `variant-${ variant }`,
    className,
  );

  return (
    <Component className={ captionClassname }>
      { children }
    </Component>
  );
};

Caption.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([ 'span', 'p', 'div' ]),
  variant: PropTypes.oneOf([ 'primary', 'secondary', 'success', 'warning', 'danger' ]),
  align: PropTypes.oneOf([ 'left', 'right', 'center' ]),
  marginTop: PropTypes.oneOfType([
    PropTypes.oneOf([ null, '4', '8', '16', '24', '32', '40', '80' ]),
    PropTypes.arrayOf(PropTypes.oneOf([ null, '4', '8', '16', '24', '32', '40', '80' ])),
  ]),
  marginBottom: PropTypes.oneOfType([
    PropTypes.oneOf([ null, '4', '8', '16', '24', '32', '40', '80' ]),
    PropTypes.arrayOf(PropTypes.oneOf([ null, '4', '8', '16', '24', '32', '40', '80' ])),
  ]),
  children: PropTypes.node,
};

Caption.defaultProps = {
  className: '',
  type: 'p',
  variant: null,
  align: 'left',
  marginTop: null,
  marginBottom: null,
  children: null,
};

export default Caption;
