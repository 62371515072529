import React from 'react';

import Icon from 'components/Icon';
import { Caption, Paragraph } from 'components/Typography';

import styles from './MessengerList.module.scss';

const services = [
  {
    type: 'telegram',
    label: 'Telegram',
    description: 'The most complete set of notifications',
    url: 'https://telegram.me/abbr_test_bot',
    featured: true,
  },
  {
    type: 'fb-messenger',
    label: 'Facebook Messenger',
    description: 'Limited choice of notifications settings',
    url: 'https://www.messenger.com/t/352306949191757',
    featured: false,
  },
  {
    type: 'whatsapp',
    label: 'WhatsApp',
    description: 'Only the most necessary notifications',
    url: '',
    featured: false,
  },
];

const MessengerList = () => {

  const renderLinks = services.map((service) => (
    <li
      className={ styles.item }
      key={ service.type }
    >
      <a
        href={ service.url }
        target="_blank"
        rel="noopener noreferrer"
        className={ styles[service.featured ? 'featured' : 'link'] }
      >
        <Icon
          size="40"
          className={ styles.icon }
          icon={ service.type }
        />
        <div className={ styles.content }>
          <Paragraph className={ styles.label }>{ service.label }</Paragraph>
          <Caption className={ styles.desc }>{ service.description }</Caption>
        </div>
      </a>
    </li>
  ));

  return (
    <ul className={ styles.list }>
      { renderLinks }
    </ul>
  );
};

MessengerList.propTypes = {};

export default MessengerList;
