import React from 'react';

import Button from 'components/Button';
import { Heading, Paragraph } from 'components/Typography';

import styles from './ErrorPage.module.scss';
import errorPicture from './kitten.jpg';
import errorPicture2x from './kitten@2x.jpg';

const ErrorPage = () => {
  return (
    <div className={ styles.page }>
      <div className={ styles.holder }>
        <img
          srcSet={ `${ errorPicture2x } 2x, ${ errorPicture }` }
          src={ errorPicture }
          className={ styles.picture }
          alt="Playful kitten"
        />
      </div>
      <Heading
        type="h1"
        size={ [ 'h4', 'h2' ] }
        align="center"
        marginTop="24"
      >
        The kitten just dropped the app
      </Heading>
      <Paragraph
        align="center"
        marginTop="16"
        marginBottom="16"
      >
        We have already scolded him
        <br />
        and are working on a fix
      </Paragraph>

      <Button to="/">Return to App</Button>
    </div>
  );
};

export default ErrorPage;
