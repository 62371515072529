import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Modal, { useModal, withModal } from 'components/Modal';
import { Heading } from 'components/Typography';

import useAction from 'shared/helpers/hooks/useAction';

import styles from './CookieConsent.module.scss';

const AllowCookiesDialog = ({
  message,
  cookie,
  onCookieAllowed,
  children,
}) => {
  const {
    openModal,
    closeModal,
  } = useModal();
  const { setAllowedCookies } = useAction();

  const handleAllow = useCallback(() => {
    setAllowedCookies({
      [cookie]: true,
    });

    typeof onCookieAllowed === 'function' && onCookieAllowed();
  }, [ cookie, onCookieAllowed, setAllowedCookies ]);

  return (
    <>
      { children({ openModal }) }
      <Modal
        animation="slide-bottom"
        position="bottom-center"
        label="Share Link"
      >
        <div className={ styles.content }>
          <Heading
            type="h4"
            size="h4"
            marginBottom="8"
            lineHeight="base"
          >
            { message }
          </Heading>
          <div className={ styles.action }>
            <Button
              variant="primary"
              onClick={ handleAllow }
            >
              Allow
            </Button>
          </div>
          <div className={ styles.action }>
            <Button
              variant="secondary"
              onClick={ closeModal }
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

AllowCookiesDialog.propTypes = {
  children: PropTypes.func.isRequired,
  cookie: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onCookieAllowed: PropTypes.func,
};

AllowCookiesDialog.defaultProps = {
  onCookieAllowed: null,
};

export default withModal(AllowCookiesDialog);
