import getErrorMessage from 'shared/helpers/functions/getErrorMessage';

import * as actionTypes from './types';

export const setAllowedCookies = (cookies) => ({
  type: actionTypes.SET_ALLOWED_COOKIES,
  cookies,
});

export const setTermsAccepted = (isTermsAccepted) => ({
  type: actionTypes.SET_TERMS_ACCEPTED,
  user: {
    isTermsAccepted,
  },
});

export const subscribeUser = (subscriptions) => ({
  type: actionTypes.SUBSCRIBE_USER,
  subscriptions,
});

export const userSubscribed = (subscriptions) => ({
  type: actionTypes.SUBSCRIBED_USER,
  user: {
    subscriptions,
  },
});

export const registerFirstVisit = () => ({
  type: actionTypes.REGISTER_FIRST_VISIT,
  user: {
    visitedFirstTime: false,
  },
});

export const checkAuth = () => ({
  type: actionTypes.CHECK_AUTH,
});

export const receivedUser = (res) => ({
  type: actionTypes.RECEIVED_USER,
  user: res,
});

export const updateUser = (user) => ({
  type: actionTypes.UPDATE_USER,
  user,
});

export const getEventsForUser = (params) => ({
  type: actionTypes.GET_EVENTS_FOR_USER,
  params,
});

export const receivedEventsForUser = (events) => ({
  type: actionTypes.RECEIVED_EVENTS_FOR_USER,
  user: {
    events,
  },
});

export const handleUserEvent = (id) => ({
  type: actionTypes.HANDLE_USER_EVENT,
  id,
});

export const loginUser = () => ({
  type: actionTypes.LOGIN_USER,
});

export const logoutUser = () => ({
  type: actionTypes.LOGOUT_USER,
});

export const loggedOutUser = () => ({
  type: actionTypes.LOGGED_OUT_USER,
});

export const unauthorizedUser = () => ({
  type: actionTypes.USER_UNAUTHORIZED,
});

export const deleteUser = () => ({
  type: actionTypes.DELETE_USER,
});

export const getAbbr = (id) => ({
  type: actionTypes.GET_ABBR,
  id,
});

export const fetchAbbrs = ({
  limit,
  offset,
} = {}) => ({
  type: actionTypes.FETCH_ABBRS,
  limit,
  offset,
});

export const receivedAbbrs = (abbrs) => {
  return {
    type: actionTypes.RECEIVED_ABBRS,
    abbrs,
  };
};

export const createAbbr = (abbr) => ({
  type: actionTypes.CREATE_ABBR,
  abbr,
});

export const createdAbbr = (abbr) => ({
  type: actionTypes.CREATED_ABBR,
  abbr,
});

export const resetCreatedAbbr = () => ({
  type: actionTypes.RESET_CREATED_ABBR,
});

export const updateCreatedAbbr = (abbr) => ({
  type: actionTypes.UPDATE_CREATED_ABBR,
  abbr,
});

export const deleteAbbr = (id) => ({
  type: actionTypes.DELETE_ABBR,
  id,
});

export const deletedAbbr = (id) => ({
  type: actionTypes.DELETED_ABBR,
  abbr: {
    id,
  },
});

export const fetchSlashtag = () => ({
  type: actionTypes.FETCH_SLASHTAG,
});

export const fetchingSlashtag = () => ({
  type: actionTypes.FETCHING_SLASHTAG,
});

export const checkSlashtag = (slashtag) => ({
  type: actionTypes.CHECK_SLASHTAG,
  slashtag,
});

export const fetchPreview = (id, url) => ({
  type: actionTypes.FETCH_PREVIEW,
  id,
  url,
});

export const updateAbbr = (abbr) => ({
  type: actionTypes.UPDATE_ABBR,
  abbr,
});

export const updatedAbbr = (abbr) => ({
  type: actionTypes.UPDATED_ABBR,
  abbr,
});

export const getTotalClicks = (ids) => ({
  type: actionTypes.GET_TOTAL_CLICKS,
  ids,
});

export const receivedTotalClicks = (abbrs) => ({
  type: actionTypes.RECEIVED_TOTAL_CLICKS,
  abbrs,
});

export const getClickCount = (id) => ({
  type: actionTypes.GET_CLICK_COUNT,
  id,
});

export const receivedClickCount = (id, data) => ({
  type: actionTypes.RECEIVED_CLICK_COUNT,
  abbr: {
    id,
    metrics: {
      views: {
        total: data.reduce((sum, current) => sum + current.value, 0),
        data,
      },
    },
  },
});

export const addError = (err, type = 'UNKNOWN_ERROR') => (dispatch) => {
  return new Promise((resolve) => {
    dispatch({
      type: actionTypes.ADD_ERROR,
      errors: [
        {
          type,
          message: getErrorMessage(type),
          native: err,
        },
      ],
    });

    resolve();
  });
};

export const removeError = (type) => (dispatch) => {
  return new Promise((resolve) => {
    dispatch({
      type: actionTypes.REMOVE_ERROR,
      error: {
        type,
      },
    });

    resolve();
  });
};

export const resetErrors = () => ({
  type: actionTypes.RESET_ERRORS,
});

export const trackEvent = (event, params) => ({
  type: actionTypes.TRACK_EVENT,
  event,
  params,
});
