/*
 This method used to programmatically navigate external links with setting 'referrer' header
 */

function navigateExternal(url) {
  const a = document.createElement('a');

  if (a.click) {
    // HTML5 browsers and IE support click() on <a>, early FF does not.
    a.setAttribute('href', url);
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
  } else {
    // Early FF can, however, use this usual method
    // where IE cannot with secure links.
    window.location = url;
  }
}

export default navigateExternal;
