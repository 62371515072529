import { useMedia } from '../hocs/withMedia';

const useFitForScreen = (size) => {

  if (size instanceof Array) {
    const [ sizeForMobile, sizeForTablet, sizeForDesktop ] = size;

    const {
      isMobile,
      isTablet,
    } = useMedia();

    if (isMobile) return sizeForMobile;
    if (isTablet) return sizeForTablet || sizeForMobile;

    return sizeForDesktop || sizeForTablet || sizeForMobile;
  }

  return size;
};

export default useFitForScreen;
