export const GET_ABBR = 'API/GET_ABBR';
export const FETCH_ABBRS = 'API/FETCH_ABBRS';
export const RECEIVED_ABBRS = 'RECEIVED_ABBRS';
export const UPDATE_ABBR = 'API/UPDATE_ABBR';
export const UPDATED_ABBR = 'UPDATED_ABBR';
export const CREATE_ABBR = 'API/CREATE_ABBR';
export const CREATED_ABBR = 'CREATED_ABBR';
export const UPDATE_CREATED_ABBR = 'UPDATE_CREATED_ABBR';
export const RESET_CREATED_ABBR = 'RESET_CREATED_ABBR';
export const DELETE_ABBR = 'API/DELETE_ABBR';
export const DELETED_ABBR = 'DELETED_ABBR';
export const FETCH_SLASHTAG = 'API/FETCH_SLASHTAG';
export const FETCHING_SLASHTAG = 'FETCHING_SLASHTAG';
export const CHECK_SLASHTAG = 'API/CHECK_SLASHTAG';
export const FETCH_PREVIEW = 'API/FETCH_PREVIEW';
export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const RESET_ERRORS = 'RESET_ERRORS';
export const GENERATE_TOKEN = 'GENERATE_TOKEN';
export const CHECK_AUTH = 'API/CHECK_AUTH';
export const RECEIVED_USER = 'RECEIVED_USER';
export const UPDATE_USER = 'API/UPDATE_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'API/LOGOUT_USER';
export const LOGGED_OUT_USER = 'LOGGED_OUT_USER';
export const USER_UNAUTHORIZED = 'USER_UNAUTHORIZED';
export const GET_EVENTS_FOR_USER = 'API/GET_EVENTS_FOR_USER';
export const RECEIVED_EVENTS_FOR_USER = 'RECEIVED_EVENTS_FOR_USER';
export const HANDLE_USER_EVENT = 'API/HANDLE_USER_EVENT';
export const DELETE_USER = 'API/DELETE_USER';
export const GET_TOTAL_CLICKS = 'API/GET_TOTAL_CLICKS';
export const RECEIVED_TOTAL_CLICKS = 'RECEIVED_TOTAL_CLICKS';
export const GET_CLICK_COUNT = 'API/GET_CLICK_COUNT';
export const RECEIVED_CLICK_COUNT = 'RECEIVED_CLICK_COUNT';
export const REGISTER_FIRST_VISIT = 'FIRST_VISIT';
export const SUBSCRIBE_USER = 'API/SUBSCRIBE_USER';
export const SUBSCRIBED_USER = 'SUBSCRIBED_USER';
export const SET_ALLOWED_COOKIES = 'SET_ALLOWED_COOKIES';
export const SET_TERMS_ACCEPTED = 'SET_TERMS_ACCEPTED';
export const TRACK_EVENT = 'ANALYTICS/TRACK_EVENT';
