import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import IconButton from 'components/IconButton';
import Input from 'components/Input';

import { getAbbrUrl } from 'store/reducers/draft';
import validateUrl from 'shared/helpers/functions/validateUrl';
import useAction from 'shared/helpers/hooks/useAction';
import useClipboard from 'shared/helpers/hooks/useClipboard';
import useDebounce from 'shared/helpers/hooks/useDebounce';

const UrlField = ({
  error,
  onError,
}) => {
  const url = useSelector(getAbbrUrl);

  const inputRef = useRef();
  const [ link, setLink ] = useState(url || '');

  const {
    supportsClipboard,
    getClipboard,
  } = useClipboard();
  const {
    updateCreatedAbbr,
  } = useAction();

  const debouncedLink = useDebounce(link, 500);

  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };

  const handlePaste = () => {
    getClipboard()
      .then(setLink)
      .then(() => inputRef.current.focus())
      .catch(() => {
      });
  };

  const handleTabFocus = useCallback(() => {
    if (inputRef?.current) {
      !inputRef.current.value && inputRef.current.focus();
    }
  }, [ url ]);

  useEffect(() => {
    window.addEventListener('focus', handleTabFocus);

    return () => {
      window.removeEventListener('blur', handleTabFocus);
    };
  }, []);

  useEffect(() => {
    if (!debouncedLink) {
      onError(null);

      updateCreatedAbbr({
        url: '',
      });

      return;
    }

    validateUrl(debouncedLink)
      .then((u) => {
        onError(null);

        return updateCreatedAbbr({
          url: u.startsWith('http') ? u : `http://${ u }`,
        });
      })
      .catch(() => {
        onError('Enter correct url');

        updateCreatedAbbr({
          url: '',
        });
      })
    ;

  }, [ debouncedLink ]);

  return (
    <Input
      inputRef={ inputRef }
      type="url"
      label="Put your link"
      onChange={ handleLinkChange }
      onPaste={ handleLinkChange }
      value={ link }
      error={ !!error }
      caption={ error }
      autoFocus={ !link }
      endAdornment={
        supportsClipboard && (
          <IconButton
            variant="ghost"
            icon="paste"
            shape="square"
            small
            onClick={ handlePaste }
          />
        )
      }
    />
  );
};

UrlField.propTypes = {
  error: PropTypes.string,
  onError: PropTypes.func,
};

UrlField.defaultProps = {
  error: null,
  onError: () => {
  },
};

export default UrlField;
