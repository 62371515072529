import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';

import styles from './Container.module.scss';

const cn = classnames.bind(styles);

const Container = ({
  className,
  narrow,
  children,
}) => {
  const containerClassName = cn(
    'container',
    narrow && 'narrow',
    className,
  );

  return (
    <section className={ containerClassName }>
      { children }
    </section>
  );
};

Container.propTypes = {
  className: PropTypes.string,
  narrow: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Container.defaultProps = {
  narrow: false,
  className: '',
};

export default Container;
