import React, { useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';

import Editable from 'components/Editable';
import Icon from 'components/Icon';

import useAction from 'shared/helpers/hooks/useAction';

import styles from './TitleField.module.scss';

const variants = {
  initial: {
    rotate: 180,
    scale: 0,
    opacity: 0,
  },
  show: {
    rotate: 0,
    scale: 1,
    opacity: 1,
  },
  exit: {
    rotate: -180,
    scale: 0,
    opacity: 0,
  },
};

const TitleField = ({
  inputRef,
  value,
  onChange,
}) => {
  const statusTimeout = useRef(0);
  const [ status, setStatus ] = useState(null);
  const [ title, setTitle ] = useState(value);
  const { trackEvent } = useAction();

  useEffect(() => {
    return () => {
      clearTimeout(statusTimeout.current);
    };
  }, []);

  const updateStatus = (newStatus, delay = 2000) => {
    setStatus(newStatus);

    statusTimeout.current = setTimeout(() => {
      setStatus(null);
    }, delay);
  };

  const handleBlur = () => {
    if (title === value) return;

    onChange({
      name: title.trim(),
      customizeData: {
        name: !!title.length,
      },
    });

    trackEvent('ABBR/TITLE');

    updateStatus('updated');
  };

  const adornment = (
    <AnimatePresence exitBeforeEnter>
      {
        status === 'updated' && (
          <motion.div
            className={ styles[status] }
            key="updated"
            variants={ variants }
            initial="initial"
            exit="exit"
            animate="show"
          >
            <Icon
              icon="refresh"
              size="12"
            />
          </motion.div>
        )
      }
    </AnimatePresence>
  );

  return (
    <Editable
      inputRef={ inputRef }
      value={ value }
      onChange={ setTitle }
      onBlur={ handleBlur }
      placeholder="Add title"
      adornment={ adornment }
    />
  );
};

TitleField.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

TitleField.defaultProps = {
  inputRef: undefined,
};

export default TitleField;
