import * as actionTypes from '../actions/types';

const initialState = {
  consentShown: false,
  auth: true,
  analytics: true,
};

const cookies = (state = initialState, action) => {
  if (action.type === actionTypes.SET_ALLOWED_COOKIES) {
    return {
      ...state,
      ...action.cookies,
    };
  }

  return state;
};

export default cookies;

export const getConsentStatus = (state) => state.cookies.consentShown;
export const getCookies = (state) => {
  const { consentShown, ...restCookies } = state.cookies;

  return restCookies;
};
