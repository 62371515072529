/* eslint-disable prefer-promise-reject-errors */
const validateSlashtag = (slashtag) => {
  return new Promise((resolve, reject) => {

    if (slashtag.length < 5) reject('SLASHTAG_SHORT');
    if (slashtag.length > 20) reject('SLASHTAG_LONG');

    resolve(slashtag);
  });
};

export default validateSlashtag;
