import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Abbr from 'components/Abbr';
import { Caption, Heading, Paragraph } from 'components/Typography';

import { getCurrentAbbr } from 'store/reducers/draft';
import { useMedia } from 'shared/helpers/hocs/withMedia';
import useAction from 'shared/helpers/hooks/useAction';
import useFirstMount from 'shared/helpers/hooks/useFirstMount';

import Nanolanding from '../Nanolanding';
import ShortLink from '../ShortLink';

import Selector from './components/Selector';

import styles from './Start.module.scss';

const Start = () => {
  const { type } = useParams();
  const {
    name,
    slashtag,
    domain,
    isFetching,
  } = useSelector(getCurrentAbbr);
  const {
    fetchSlashtag,
    updateCreatedAbbr,
  } = useAction();
  const [ abbrValid, setAbbrValid ] = useState(true);
  const { isDesktop } = useMedia();

  useFirstMount(() => {
    if (!slashtag && !isFetching) {
      fetchSlashtag();
    }
  });

  const handleChange = (abbrData) => updateCreatedAbbr({ ...abbrData });

  const handleAbbrValid = () => setAbbrValid(true);
  const handleAbbrError = () => setAbbrValid(false);

  useEffect(() => {
    handleChange({ type: type === 'nanolanding' ? 'page' : 'redirect' });
  }, [ type ]);

  return (
    <div className={ styles.page }>
      {
        isDesktop && (
          <Heading type="h2">
            New abbr
          </Heading>
        )
      }

      <div className={ styles.abbr }>
        {
          !isDesktop && (
            <Paragraph
              marginBottom="4"
            >
              Your abbr
            </Paragraph>
          )
        }

        <Abbr
          domain={ domain }
          name={ name }
          slashtag={ slashtag }
          onChange={ handleChange }
          onValid={ handleAbbrValid }
          onError={ handleAbbrError }
          type={ type === 'nanolanding' ? 'page' : 'redirect' }
        />
      </div>

      <div className={ styles.navigation }>
        <Paragraph
          className={ styles.label }
          marginBottom={ [ '4', '8' ] }
        >
          Choose type
        </Paragraph>

        <Selector />
      </div>

      <div className={ styles.screen }>
        {
          type === 'short-link' && (
            <>
              <Caption className={ styles.description }>Makes your long url prettier and adds new features</Caption>
              <ShortLink error={ !abbrValid } />
            </>
          )
        }
        {
          type === 'nanolanding' && (
            <>
              <Caption className={ styles.description }>All your links on one page with personalized design.</Caption>
              <Nanolanding />
            </>
          )
        }
      </div>
    </div>
  );
};

export default Start;
