import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getConsentStatus, getCookies } from 'store/reducers/cookies';

import { GA_TRACKING_ID, METRIKA_TRACKING_ID } from 'shared/constants';

const useAnalytics = () => {
  const cookies = useSelector(getCookies);
  const consentShown = useSelector(getConsentStatus);

  useEffect(() => {
  }, []);

  useEffect(() => {
    if (consentShown) {
      window[`ga-disable-${ GA_TRACKING_ID }`] = !cookies.analytics || undefined;
      window[`disableYaCounter${ METRIKA_TRACKING_ID }`] = !cookies.analytics || undefined;

      window.dataLayer && window.dataLayer.push({ GDPR_GRANTED: cookies.analytics });
    } else {
      window[`ga-disable-${ GA_TRACKING_ID }`] = true;
      window[`disableYaCounter${ METRIKA_TRACKING_ID }`] = true;
    }
  }, [ cookies.analytics, consentShown ]);
};

export default useAnalytics;
