import merge from 'lodash/merge';

import { ABBR_DOMAIN } from '../../shared/constants';
import * as actionTypes from '../actions/types';

const abbrState = {
  id: '',
  type: 'redirect',
  status: 'active',
  url: '',
  name: '',
  domain: ABBR_DOMAIN,
  preview: {
    title: '',
    description: '',
    image: '',
  },
  slashtag: '',
  metrics: {
    views: {
      total: 0,
      data: [],
    },
  },
  tracking: {
    facebook: '',
    google: '',
  },
  customize: [],
};

const abbrReducer = (state = abbrState, action) => {
  switch (action.type) {
    case actionTypes.RECEIVED_ABBRS:
      return merge({}, abbrState, state);
    case actionTypes.RECEIVED_TOTAL_CLICKS:
      return merge({}, state, action.abbr);
    case actionTypes.CREATED_ABBR:
      return merge({}, state, action.abbr);
    case actionTypes.UPDATED_ABBR:
    case actionTypes.RECEIVED_CLICK_COUNT:
      return {
        ...state,
        ...action.abbr,
        tracking: merge({}, state.tracking, action.abbr.tracking || {}),
      };
    default:
      return state;
  }
};

export default abbrReducer;

export const getAbbrById = (id) => (state) => state.abbrs[id] || {};
