import React, { useEffect, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { compose } from 'recompose';
import WebFont from 'webfontloader';

import Page404 from 'pages/404';
import Abbr from 'pages/Abbr';
import AbbrsList from 'pages/AbbrsList';
import Create from 'pages/Create';
import Onboarding from 'pages/Onboarding';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import Profile from 'pages/Profile';
import TermsAndConditions from 'pages/TermsAndConditions';

import Container from 'components/Container';
import CookieConsent from 'components/CookieConsent';
import Footer from 'components/Footer';
import Header from 'components/Header';
import withLoader from 'components/Loader';
import ProtectedRoute from 'components/ProtectedRoute';

import withStore from 'store';
import { getConsentStatus } from 'store/reducers/cookies';
import { getUser } from 'store/reducers/user';
import withMedia, { useMedia } from 'shared/helpers/hocs/withMedia';
import withRouterContext from 'shared/helpers/hocs/withRouterContext';
import useAction from 'shared/helpers/hooks/useAction';
import useAnalytics from 'shared/helpers/hooks/useAnalytics';
import useFirstMount from 'shared/helpers/hooks/useFirstMount';

import { TAG_MANAGER_ID } from 'shared/constants';

import Subscription from './pages/Subscription';

import 'assets/styles/global.scss';

import styles from './App.module.scss';

const tagManagerArgs = {
  gtmId: TAG_MANAGER_ID,
  dataLayer: {
    GDPR_GRANTED: false,
  },
};

TagManager.initialize(tagManagerArgs);

function App() {
  const referrer = useRef('/');
  const user = useSelector(getUser);
  const [ isFetching, setFetching ] = useState(true);
  const history = useHistory();
  const {
    fetchAbbrs,
    checkAuth,
    getEventsForUser,
    trackEvent,
    handleUserEvent,
  } = useAction();
  const consentShown = useSelector(getConsentStatus);
  const { isDesktop } = useMedia();

  useAnalytics();

  useFirstMount(() => {
    checkAuth()
      .catch(() => {
        if (!user.token) {
          history.push('/');
        }
      });

    if (window.location.search) {
      history.replace(window.location.pathname);
    }

    fetchAbbrs()
      .then(() => setFetching(false));
  });

  useEffect(() => {
    if (user.isLoggedIn) {
      getEventsForUser();
    }
  }, [ user.isLoggedIn ]);

  useEffect(() => {
    if (!user.events || user.events.length === 0) return;

    const registrationEvent = Object.values(user.events).find(({
      type,
      handled,
    }) => type === 'user_registration' && !handled);

    const loginEvent = Object.values(user.events).find(({
      type,
      handled,
    }) => type === 'user_login' && !handled);

    if (registrationEvent) {
      trackEvent('USER/SIGN_UP');

      handleUserEvent(registrationEvent.id);

      if (window.location.pathname !== '/subscription') {
        referrer.current = window.location.pathname;
      }

      history.push('/subscription', {
        referrer: referrer.current,
      });
    }

    if (loginEvent) {
      trackEvent('USER/LOG_IN');

      handleUserEvent(loginEvent.id);
    }
  }, [ user.events ]);

  useEffect(() => {
    if (!isFetching) {
      document.getElementById('preloader').remove();
    }
  }, [ isFetching ]);

  useEffect(() => {
    WebFont.load({
      google: {
        families: [ 'Cuprum:400,700', 'sans-serif' ],
      },
    });
  }, []);

  if (isFetching) return null;

  return (
    <div className={ styles.app }>
      <Header />

      <Switch>
        <Route
          path="/"
          exact
        >
          <Onboarding />
        </Route>
        <Route
          path={ [ '/', '/create', '/create/:type', '/create/:type/:id', '/profile', '/profile/notifications', '/abbrs', '/abbrs/:id', '/subscription' ] }
          exact
        >

          <Container className={ styles.container }>
            {
              isDesktop && (
                <Route
                  path={ [ '/create', '/create/:type', '/create/:type/:id', '/profile', '/abbrs', '/abbrs/:id', '/subscription' ] }
                  exact
                >
                  <div className={ styles.sidebar }>
                    <AbbrsList />
                  </div>
                </Route>
              )
            }

            <div className={ styles.content }>
              <Switch>
                <Route
                  path="/abbrs"
                  exact
                >
                  { !isDesktop ? <AbbrsList /> : <Redirect to="/create" /> }
                </Route>
                <Route
                  path="/abbrs/:id"
                  exact
                >
                  <Abbr />
                </Route>
                <Route path="/create">
                  <Create />
                </Route>
                <ProtectedRoute
                  path="/subscription"
                  exact
                >
                  <Subscription />
                </ProtectedRoute>
                <ProtectedRoute
                  path={ [ '/profile', '/profile/notifications' ] }
                  exact
                >
                  <Profile />
                </ProtectedRoute>
              </Switch>
            </div>
          </Container>
        </Route>
        <Route
          path="/privacy-policy"
          exact
        >
          <PrivacyPolicy />
        </Route>
        <Route
          path="/terms"
          exact
        >
          <TermsAndConditions />
        </Route>
        <Route path="*">
          <Page404 />
        </Route>
      </Switch>

      {
        !isDesktop ? (
          <Switch>
            <Route
              path={ [ '/create/:type', '/create/:type/:id', '/abbrs', '/abbrs/:id', '/profile', '/profile/notifications', '/subscription', '/' ] }
              exact
            />
            <Route path="*">
              <Footer />
            </Route>
          </Switch>
        ) : (
          <Footer />
        )
      }

      { !consentShown && <CookieConsent initiallyOpen /> }
    </div>
  );
}

export default compose(
  withStore,
  withRouterContext,
  withLoader,
  withMedia,
)(App);
