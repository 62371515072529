import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';

import styles from './Button.module.scss';

const cn = classnames.bind(styles);

const Button = ({
  buttonRef,
  to,
  href,
  type,
  variant,
  block,
  download,
  target,
  disabled,
  onClick,
  children,
  className,
  ...restProps
}) => {
  const buttonClassName = cn(
    variant,
    disabled && 'disabled',
    block && `block-${ block === true ? 'both' : block }`,
    className,
  );

  let ButtonComponent = null;

  switch (true) {
    case to !== null:
      ButtonComponent = Link;

      break;
    case typeof href === 'string':
      ButtonComponent = 'a';

      break;
    default:
      ButtonComponent = 'button';
  }

  const renderedChildren = React.Children.map(children, (child) => {
    if (child.type === Icon) {
      return (<span className={ styles.icon }>{ child }</span>);
    }

    if (typeof child === 'string') {
      return (<span className={ styles.label }>{ child }</span>);
    }

    return child;
  });

  return (
    <ButtonComponent
      ref={ buttonRef }
      to={ !disabled ? to : undefined }
      href={ !disabled ? href : undefined }
      type={ type }
      download={ download }
      target={ target }
      className={ buttonClassName }
      disabled={ disabled }
      onClick={ !disabled ? onClick : undefined }
      { ...restProps }
    >
      { renderedChildren }
    </ButtonComponent>
  );
};

Button.propTypes = {
  buttonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  to: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]),
  href: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.oneOf([ 'primary', 'secondary', 'success', 'danger', 'warning', 'ghost' ]),
  block: PropTypes.oneOf([ 'mobile', 'desktop', true, false ]),
  disabled: PropTypes.bool,
  download: PropTypes.bool,
  target: PropTypes.oneOf([ '_blank', '_top', '_parent' ]),
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
};

Button.defaultProps = {
  buttonRef: undefined,
  to: null,
  href: null,
  type: 'button',
  variant: 'primary',
  block: false,
  download: null,
  target: undefined,
  disabled: false,
  children: null,
  className: '',
  onClick: () => {
  },
};

export default Button;
