import React from 'react';

import LoginButton from 'components/LoginButton';
import Navigation from 'components/Navigation';

import { useMedia } from 'shared/helpers/hocs/withMedia';

const NotLoggedIn = () => {
  const { isMobile } = useMedia();

  if (isMobile) {
    return <Navigation />;
  }

  return <LoginButton />;
};

export default NotLoggedIn;
