import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

const ModalContext = createContext();

function withModal(WrappedComponent, defaultOpen) {
  function WithModal(props) {
    const openingTimeout = useRef(0);
    const closingTimeout = useRef(0);

    const [ isOpen, setOpen ] = useState(defaultOpen);

    const [ opening, setOpening ] = useState(false);
    const [ closing, setClosing ] = useState(false);

    useEffect(() => {
      return () => {
        clearTimeout(openingTimeout.current);
        clearTimeout(closingTimeout.current);
      };
    }, []);

    const handleOpen = () => {
      setOpening(true);
      setOpen(true);
      openingTimeout.current = setTimeout(() => setOpening(false), 350);
    };

    const handleClose = () => {
      setClosing(true);
      setOpen(false);
      closingTimeout.current = setTimeout(() => setClosing(false), 350);
    };

    const toggleModal = () => isOpen ? handleOpen() : handleClose();

    const contextValue = {
      opening,
      closing,
      openModal: handleOpen,
      closeModal: handleClose,
      toggleModal,
      isOpen,
    };

    return (
      <ModalContext.Provider value={ contextValue }>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */ }
        <WrappedComponent { ...props } />
      </ModalContext.Provider>
    );
  }

  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  WithModal.displayName = `withModal(${ wrappedComponentName })`;

  return WithModal;
}

export const useModal = () => useContext(ModalContext);

export default withModal;
