import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';

import Icon from '../Icon';

import styles from './AbbrIcon.module.scss';

const cn = classnames.bind(styles);

const AbbrIcon = ({
  size,
  type,
}) => {
  const typeClassName = cn(
    type,
    `size-${ size }`,
  );

  return (
    <div className={ typeClassName }>
      <Icon
        size={ size }
        icon={ type === 'redirect' ? 'link' : 'grid' }
        initialTransition
      />
    </div>
  );
};

AbbrIcon.propTypes = {
  size: PropTypes.oneOf([ '16', '20', '24', '32' ]),
  type: PropTypes.oneOf([ 'redirect', 'page' ]).isRequired,
};

AbbrIcon.defaultProps = {
  size: '16',
};

export default AbbrIcon;
