import { applyMiddleware, createStore as reduxCreateStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import analyticsMiddleware from './middlewares/analyticsMiddleware';
import fetchMiddleware from './middlewares/fetchMiddleware';
import persistedReducer from './reducers';

const middlewares = [
  thunk,
  analyticsMiddleware,
  fetchMiddleware,
];

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});

const createStore = () => {
  const store = reduxCreateStore(
    persistedReducer,
    {},
    composeEnhancers(applyMiddleware(...middlewares)),
  );
  const persistor = persistStore(store);

  return {
    store,
    persistor,
  };
};

export default createStore;
