import React from 'react';
import { useSelector } from 'react-redux';

import { getUser } from 'store/reducers/user';

import LoggedIn from './components/LoggedIn';
import NotLoggedIn from './components/NotLoggedIn';

const UserBlock = () => {
  const {
    isLoggedIn,
    ...userData
  } = useSelector(getUser);

  return isLoggedIn ? (
    <LoggedIn { ...userData } />
  ) : (
    <NotLoggedIn />
  );
};

export default UserBlock;
