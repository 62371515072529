/* eslint-disable no-case-declarations */
import api from 'shared/api';

import {
  createdAbbr,
  deletedAbbr,
  fetchingSlashtag,
  loggedOutUser,
  receivedAbbrs,
  receivedClickCount,
  receivedEventsForUser,
  receivedTotalClicks,
  receivedUser,
  unauthorizedUser,
  updateCreatedAbbr,
  updatedAbbr,
  userSubscribed,
} from '../actions';
import * as actionTypes from '../actions/types';

const fetchMiddleware = ({
  dispatch,
  getState,
}) => (next) => (action) => {
  if (!action.type.startsWith('API/')) {
    return next(action);
  }

  const abbr = getState().draft;
  const { user } = getState();

  switch (action.type) {
    case actionTypes.SUBSCRIBE_USER:
      // TODO add api integration
      dispatch(userSubscribed(action.subscriptions));

      return Promise.resolve();
    case actionTypes.GET_CLICK_COUNT:
      const { id } = action;

      return api.getClickCount(id)
        .then((res) => dispatch(receivedClickCount(id, res)));

    case actionTypes.CHECK_AUTH:
      return api.getUser()
        .then((res) => dispatch(receivedUser(res)))
        .catch((err) => {
          if (err.type === 'UNAUTHORIZED') {
            return dispatch(unauthorizedUser());
          }

          return Promise.reject(err);
        });

    case actionTypes.LOGOUT_USER:
      return api.logoutUser(user)
        .then((res) => dispatch(loggedOutUser(res)));

    case actionTypes.UPDATE_USER:
      if (!action.user.id) {
        return dispatch(receivedUser({ ...action.user }));
      }

      return api.updateUser({ ...action.user })
        .then(() => dispatch(receivedUser({ ...action.user })))
        .catch((err) => {
          if (err.type === 'UNAUTHORIZED') {
            return dispatch(unauthorizedUser());
          }

          return Promise.reject(err);
        });

    case actionTypes.GET_EVENTS_FOR_USER:
      return api.getEventsForUser(action.params)
        .then((events) => dispatch(receivedEventsForUser(events)))
        .catch((err) => {
          if (err.type === 'UNAUTHORIZED') {
            return dispatch(unauthorizedUser());
          }

          return Promise.reject(err);
        });

    case actionTypes.HANDLE_USER_EVENT:
      return api.handleUserEvent(action.id)
        .then((events) => dispatch(receivedEventsForUser(events)))
        .catch((err) => {
          if (err.type === 'UNAUTHORIZED') {
            return dispatch(unauthorizedUser());
          }

          return Promise.reject(err);
        });

    case actionTypes.DELETE_USER:
      return api.deleteUser(user)
        .then((res) => dispatch(loggedOutUser(res)))
        .catch((err) => {
          if (err.type === 'UNAUTHORIZED') {
            return dispatch(unauthorizedUser());
          }

          return Promise.reject(err);
        });

    case actionTypes.FETCH_ABBRS:
      return api.fetchAbbrs({
        limit: action.limit,
        offset: action.offset,
      }, user)
        .then((res) => dispatch(receivedAbbrs([ ...res ])));

    case actionTypes.GET_TOTAL_CLICKS:
      return api.getTotalCount(action.ids)
        .then((res) => dispatch(receivedTotalClicks([ ...res ])))
        .catch((err) => {
          if (err.type === 'UNAUTHORIZED') {
            return dispatch(unauthorizedUser());
          }

          return Promise.reject(err);
        });

    case actionTypes.GET_ABBR:
      return api.getAbbr(action.id)
        .then((res) => dispatch(updatedAbbr({ id: action.id, ...res })));

    case actionTypes.CREATE_ABBR:
      const {
        slashtag,
        url,
        type: abbrType,
        tracking,
        name,
        domain,
        customize,
      } = abbr;

      return api.createAbbr({
        url,
        name,
        type: abbrType,
        slashtag,
        ...(tracking.facebook || tracking.google ? { tracking } : {}),
        customize,
      }, user)
        .then((payload) => {
          dispatch(createdAbbr({
            ...abbr,
            ...payload,
            name,
            domain,
            slashtag: payload.slashtag,
            customize,
          }));

          return Promise.resolve(payload);
        });

    case actionTypes.DELETE_ABBR:
      return api.deleteAbbr(action.id)
        .then(() => dispatch(deletedAbbr(action.id)));

    case actionTypes.FETCH_PREVIEW:
      return api.fetchPreview(action.url)
        .then((res) => dispatch(action.id
          ? updatedAbbr({
            id: action.id,
            preview: res,
          })
          : updateCreatedAbbr({
            name: res.title,
            preview: res,
          })));

    case actionTypes.FETCH_SLASHTAG:
      dispatch(fetchingSlashtag());

      return api.fetchSlashtag(user)
        .then((res) => dispatch(updateCreatedAbbr(res)));

    case actionTypes.UPDATE_ABBR:
      if (!action.abbr.id) {
        return dispatch(updatedAbbr({ ...action.abbr }));
      }

      return api.updateAbbr({ ...action.abbr })
        .then((res) => {
          return dispatch(updatedAbbr({ ...action.abbr, ...res }));
        });

    case actionTypes.CHECK_SLASHTAG:
      return api.checkSlashtag(action.slashtag, user)
        .then((res) => res);

    default:
      return next(action);
  }
};

export default fetchMiddleware;
