import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AnimatePresence, AnimateSharedLayout, motion, useElementScroll, useViewportScroll } from 'framer-motion';
import throttle from 'lodash/throttle';

import Button from 'components/Button';
import Divider from 'components/Divider';
import Icon from 'components/Icon';
import { Loader } from 'components/Loader';
import { Heading, Paragraph } from 'components/Typography';

import { getAbbrs } from 'store/reducers/abbrs';
import { getUser } from 'store/reducers/user';
import { useMedia } from 'shared/helpers/hocs/withMedia';
import useAction from 'shared/helpers/hooks/useAction';
import useFirstMount from 'shared/helpers/hooks/useFirstMount';

import ListItem from './components/ListItem';

import styles from './AbbrsList.module.scss';

const AbbrsList = () => {
  const listRef = useRef(document.createElement('ul'));
  const countInterval = useRef(0);
  const prevScroll = useRef(0);
  const [ isFetching, setFetching ] = useState(true);
  const [ interactedId, setInteractedId ] = useState(null);
  const { scrollYProgress: viewportScroll } = useViewportScroll();
  const { scrollYProgress: elementScroll } = useElementScroll(listRef);
  const { isDesktop } = useMedia();

  const history = useHistory();
  const { id } = useParams();

  const { isLoggedIn } = useSelector(getUser);
  const abbrs = useSelector(getAbbrs);

  const {
    fetchAbbrs,
    getTotalClicks,
  } = useAction();

  const offset = useRef(abbrs.length);

  const listVariants = {
    initial: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 5,
      },
    },
    exit: {
      opacity: 0,
      x: !isDesktop ? '50%' : '-50%',
      transition: {
        ease: 'easeInOut',
      },
    },
  };

  const handleScroll = useCallback(throttle((value) => {
    if (isFetching) return;

    if (value > 0.5 && value > prevScroll.current) {
      setFetching(true);

      fetchAbbrs({
        limit: 20,
        offset: offset.current,
      })
        .then(() => {
          setFetching(false);
          offset.current += 20;
        });
    }

    prevScroll.current = value;
  }, 1000), [ isFetching ]);

  useFirstMount(() => {
    fetchAbbrs()
      .then(() => {
        if (abbrs.length > 0 && isLoggedIn) {
          return getTotalClicks(abbrs.map((abbr) => abbr.id));
        }

        return Promise.resolve();
      })
      .then(() => setFetching(false));
  });

  useEffect(() => {
    setInteractedId(null);
  }, []);

  useEffect(() => {
    if (isDesktop) return;

    const unsubscribeViewport = viewportScroll.onChange(handleScroll);

    return () => {
      unsubscribeViewport();
    };
  }, [ abbrs.length, fetchAbbrs, isFetching, viewportScroll ]);

  useEffect(() => {
    if (!isDesktop) return;

    const unsubscribeElement = elementScroll.onChange(handleScroll);

    return () => {
      unsubscribeElement();
    };
  }, [ abbrs.length, fetchAbbrs, isFetching, elementScroll ]);

  useEffect(() => {
    if (abbrs.length > 0 && isLoggedIn) {
      countInterval.current = setInterval(() => {
        getTotalClicks(abbrs.map((abbr) => abbr.id));
      }, 10000);
    }

    return () => {
      clearInterval(countInterval.current);
    };
  }, [ isLoggedIn, abbrs.length ]);

  const renderItems = useCallback(() =>
    abbrs.map((abbr) => (
      <motion.li
        key={ abbr.id }
        variants={ listVariants }
        initial="initial"
        animate="show"
        exit="exit"
        layout
        className={ styles.item }
      >
        {
          id === abbr.id && (
            <motion.div
              className={ styles.indicator }
              layoutId="indicator"
            />
          )
        }
        <ListItem
          { ...abbr }
          interacted={ interactedId === abbr.id }
          to={ `/abbrs/${ abbr.id }` }
          onInteract={ setInteractedId }
        />
      </motion.li>
    )), [ id, abbrs, history, interactedId ]);

  return (
    <div className={ styles.page }>
      {
        isDesktop && (
          <div className={ styles.headline }>
            <Heading
              type="h2"
              size="h3"
              align="center"
            >
              Your abbrs
            </Heading>

            <Button
              variant="ghost"
              to="/create"
            >
              Create new
              <Icon icon="plus-circled" />
            </Button>
          </div>
        )
      }

      {
        abbrs.length > 0 ? (
          <AnimateSharedLayout>
            <motion.ul
              className={ styles.list }
              ref={ listRef }
              variants={ listVariants }
              initial="initial"
              animate="show"
              layout
            >
              <AnimatePresence>
                { renderItems() }
              </AnimatePresence>
            </motion.ul>
          </AnimateSharedLayout>
        ) : (
          <Paragraph
            align="center"
            marginTop="24"
            marginBottom="24"
          >
            You don`t have any abbrs yet
          </Paragraph>
        )
      }
      {
        isFetching && (
          <div className={ styles.loader }>
            <Loader>Fetching abbrs...</Loader>
          </div>
        )
      }

      {
        !isDesktop && (
          <div className={ styles.create }>
            <Divider marginTop={ null } />

            <Button
              to="/create"
              block
            >
              Create new
              <Icon icon="plus-circled" />
            </Button>
          </div>
        )
      }
    </div>
  );
}
;

export default AbbrsList;
