import React from 'react';

import Button from 'components/Button';
import Container from 'components/Container';
import { Heading, Paragraph } from 'components/Typography';

import { ReactComponent as Illustration } from './404.svg';
import styles from './Page404.module.scss';

const Page404 = () => {
  return (
    <Container className={ styles.page }>
      <Illustration className={ styles.illustration } />
      <Heading
        type="h1"
        size={ [ 'h4', 'h2' ] }
        align="center"
        marginTop="24"
      >
        These aren’t page you’re looking for…
      </Heading>
      <Paragraph
        align="center"
        marginTop="16"
        marginBottom="16"
      >
        Check the url is correct
        <br />
        or return to app
      </Paragraph>

      <Button to="/">Return to App</Button>
    </Container>
  );
};

export default Page404;
