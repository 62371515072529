import { TRACK_EVENT } from '../actions/types';

const analyticsMiddleware = () => (next) => (action) => {
  if (action.type !== TRACK_EVENT) return next(action);

  const track = (params) => {
    window.dataLayer.push(params);
  };

  const {
    event,
    params,
  } = action;

  switch (event) {
    case 'ABBR/TITLE':
      track({
        event: 'customize_abbr',
        target: 'title',
      });

      return Promise.resolve();

    case 'ABBR/URL':
      track({
        event: 'customize_abbr',
        target: 'url',
      });

      return Promise.resolve();

    case 'ABBR/SLASHTAG':
      track({
        event: 'customize_abbr',
        target: 'slashtag',
      });

      return Promise.resolve();

    case 'ABBR/PIXEL':
      track({
        event: 'customize_pixel',
        target: params.pixels.join(', '),
      });

      return Promise.resolve();

    case 'ABBR/PIXEL/PASTE':
      track({
        event: 'customize_pixel',
        target: 'paste',
      });

      return Promise.resolve();

    case 'ABBR/PIXEL/MANUAL_INPUT':
      track({
        event: 'customize_pixel',
        target: 'manual_input',
      });

      return Promise.resolve();

    case 'PROFILE/CHANGE_NAME':
      track({
        event: 'profile_update',
        target: 'username',
      });

      return Promise.resolve();

    case 'USER/LOG_IN':
      track({
        event: 'log_in',
      });

      return Promise.resolve();

    case 'USER/SIGN_UP':
      track({
        event: 'sign_up',
      });

      return Promise.resolve();

    default:
      return Promise.reject(new TypeError('Incorrect analytics event type'));
  }
};

export default analyticsMiddleware;
