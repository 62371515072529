import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

const MediaContext = createContext();

function withMedia(WrappedComponent) {
  function WithMedia(props) {
    const [ width, setWidth ] = useState(window.innerWidth);

    const handleResize = useCallback(() => {
      setWidth(window.innerWidth);
    }, []);

    useEffect(() => {
      handleResize();

      window.addEventListener('resize', handleResize);
      window.addEventListener('orientationchange', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('orientationchange', handleResize);
      };
    }, []);

    const contextValue = {
      screenWidth: width,
      isMobile: width < 768,
      isTablet: width >= 768 && width < 1024,
      isDesktop: width >= 1024,
    };

    return (
      <MediaContext.Provider value={ contextValue }>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */ }
        <WrappedComponent { ...props } />
      </MediaContext.Provider>
    );
  }

  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  WithMedia.displayName = `withMedia(${ wrappedComponentName })`;

  return WithMedia;
}

export const useMedia = () => useContext(MediaContext);

export default withMedia;
