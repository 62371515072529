import React, { useEffect, useState } from 'react';
import { eachDayOfInterval, isSameDay, isThisMonth, isThisWeek, isToday, toDate } from 'date-fns';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Chart from 'components/Chart';
import Icon from 'components/Icon';

import styles from './Views.module.scss';

const fillDates = (data) => {
  if (data.length === 0) return [];

  const start = toDate(get(data, [ 0, 'date' ]));
  const end = new Date();

  const range = eachDayOfInterval({
    start,
    end,
  });

  return range.map((d) => {
    return {
      date: d.getTime(),
      value: data.find(({ date }) => isSameDay(toDate(date), d))?.value || 0,
    };
  });
};

const Views = ({
  data,
}) => {
  const [ currentPreset, setPreset ] = useState(2);
  const [ filteredData, setFilteredData ] = useState(fillDates(data));

  const total = data.reduce((sum, item) => sum + item.value, 0);
  const today = isToday(toDate(get(data, [ data.length - 1, 'date' ]))) ? get(data, [ data.length - 1, 'value' ], '0') : 0;

  const handleTabs = (preset) => () => {
    setPreset(preset);
  };

  useEffect(() => {
    const filledData = fillDates(data);

    if (currentPreset === 0) {
      setFilteredData(filledData.filter((item) => {
        const date = toDate(item.date);

        return isThisWeek(date);
      }));

      return;
    }

    if (currentPreset === 1) {
      setFilteredData(filledData.filter((item) => {
        const date = toDate(item.date);

        return isThisMonth(date);
      }));

      return;
    }

    setFilteredData(filledData);
  }, [ currentPreset, data ]);

  return (
    <div className={ styles.views }>
      <div className={ styles.info }>
        <span className={ styles.label }>
          Today clicks:
          { ' ' }
          { today }
          { ' ' }
          <Icon
            className={ styles.icon }
            icon="eye"
            size="12"
          />
        </span>

        <span className={ styles.label }>
          Total clicks:
          { ' ' }
          { total }
          { ' ' }
          <Icon
            className={ styles.icon }
            icon="eye"
            size="12"
          />
        </span>
      </div>

      <div className={ styles.tabs }>
        <div className={ styles[currentPreset === 0 ? 'activeTab' : 'tab'] }>
          <Button
            variant="ghost"
            onClick={ handleTabs(0) }
            data-stat="week"
          >
            Last Week
          </Button>
        </div>
        <div className={ styles[currentPreset === 1 ? 'activeTab' : 'tab'] }>
          <Button
            variant="ghost"
            onClick={ handleTabs(1) }
            data-stat="month"
          >
            Last Month
          </Button>
        </div>
        <div className={ styles[currentPreset === 2 ? 'activeTab' : 'tab'] }>
          <Button
            variant="ghost"
            onClick={ handleTabs(2) }
            data-stat="all"
          >
            All Time
          </Button>
        </div>
      </div>

      <div className={ styles.chart }>
        <Chart data={ filteredData } />
      </div>
    </div>
  );
};

Views.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  })).isRequired,
};


export default Views;
