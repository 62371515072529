import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

function withRouter(WrappedComponent) {
  function WithRouter(props) {


    return (
      <Router>
        <WrappedComponent { ...props } />
      </Router>
    );
  }

  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  WithRouter.displayName = `withRouter(${ wrappedComponentName })`;

  return WithRouter;
}

export default withRouter;