import React from 'react';
import PropTypes from 'prop-types';

import { useMedia } from '../../shared/helpers/hocs/withMedia';
import Button from '../Button';
import Modal, { useModal, withModal } from '../Modal';
import { Heading, Paragraph } from '../Typography';

import styles from './CookieConsent.module.scss';

const ConfirmationDialog = ({
  children,
  onDecline,
  onConfirm,
}) => {
  const {
    isOpen,
    closing,
    openModal,
    closeModal,
  } = useModal();
  const { isDesktop } = useMedia();

  const handleDecline = () => {
    onDecline();
    closeModal();

    return Promise.resolve();
  };

  const handleConfirm = () => {
    onConfirm();
    closeModal();
  };

  return (
    <>
      { children({ openModal }) }
      {
        (isOpen || closing) && (
          <Modal
            label="Confirmation"
            animation={ !isDesktop ? 'slide-bottom' : 'slide-top' }
            position={ !isDesktop ? 'bottom-center' : 'top-center' }
            onRequestClose={ handleDecline }
          >
            <div className={ styles.content }>
              <Heading type="h4">Are you sure want to disable authorization cookies?</Heading>
              <Paragraph>You will be logged out and won`t be able to login again with disabled authorization cookies.</Paragraph>

              <div className={ styles.action }>
                <Button
                  variant="danger"
                  onClick={ handleConfirm }
                  block
                >
                  Disable cookies
                </Button>
                <Button
                  variant="secondary"
                  onClick={ handleDecline }
                  block
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Modal>
        )
      }
    </>
  );
};

ConfirmationDialog.propTypes = {
  children: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  onDecline: PropTypes.func,
};

ConfirmationDialog.defaultProps = {
  onConfirm: () => {
  },
  onDecline: () => {
  },
};

export default withModal(ConfirmationDialog);
