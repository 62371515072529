import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';

import styles from './Divider.module.scss';

const cn = classnames.bind(styles);

const Divider = ({
  className,
  marginTop,
  marginBottom,
}) => {
  const dividerClassname = cn(
    'divider',
    `mt-${ marginTop }`,
    `mb-${ marginBottom }`,
    className,
  );

  return (
    <hr className={ dividerClassname } />
  );
};

Divider.propTypes = {
  className: PropTypes.string,
  marginTop: PropTypes.oneOf([ null, '4', '8', '16', '24', '32', '40', '80' ]),
  marginBottom: PropTypes.oneOf([ null, '4', '8', '16', '24', '32', '40', '80' ]),
};

Divider.defaultProps = {
  className: '',
  marginTop: '16',
  marginBottom: '16',
};

export default Divider;
