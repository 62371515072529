/* eslint-disable no-case-declarations */
import merge from 'lodash/merge';

import * as actionTypes from '../actions/types';

import abbrReducer from './abbr';

const abbrs = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATED_ABBR:
      return {
        [action.abbr.id]: abbrReducer(undefined, action),
        ...state,
      };
    case actionTypes.RECEIVED_CLICK_COUNT:
    case actionTypes.UPDATED_ABBR:
      if (!action.abbr.id) return state;

      return {
        ...state,
        [action.abbr.id]: abbrReducer(state[action.abbr.id], action),
      };
    case actionTypes.DELETED_ABBR:
      const {
        [action.abbr.id]: deletedAbbr,
        ...nextState
      } = state;

      return nextState;
    case actionTypes.RECEIVED_TOTAL_CLICKS:
      const abbrsWithClicks = action.abbrs.reduce((dict, abbr) => {
        const newState = { ...dict };

        if (Object.keys(dict).includes(abbr.id)) {
          Object.assign(newState, {
            [abbr.id]: abbrReducer(state[abbr.id], {
              type: action.type,
              abbr,
            }),
          });
        }

        return newState;
      }, state);

      return merge({}, state, abbrsWithClicks);
    case actionTypes.RECEIVED_ABBRS:
      const abbrsData = action.abbrs.reduce((dict, abbr) =>
        Object.assign(dict, {
          [abbr.id]: abbrReducer(abbr, action),
        }), {});

      return merge({}, state, abbrsData);
    case actionTypes.LOGGED_OUT_USER:
      return {};
    default:
      return state;
  }
};

export default abbrs;

/*
 selectors
 */
export const getAbbrs = (state) => Object.values(state.abbrs).filter((abbr) => abbr.id);
export const haveAbbrs = (state) => Object.keys(state.abbrs).length > 0;
export const getAbbrBySlashtag = (slashtag) => (state) => Object.values(state.abbrs).find((abbr) => abbr.slashtag === slashtag) || {};
