import React from 'react';

import Article from 'components/Article';
import Button from 'components/Button';

import styles from './TermsAndConditions.module.scss';

const TermsAndConditions = () => {
  return (
    <div className={ styles.page }>
      <Article>
        <h1>
          Terms and Conditions
        </h1>

        <h2>
          Review of this Policy
        </h2>

        <p>
          We keep this policy under regular review and have last updated it in October 2018.
        </p>

        <h2>
          Who we are
        </h2>

        <p>
          Charge Automotive is a British company based in London. Our team has extensive experience in developing high-performance vehicles ranging from racing series to touring cars. Our engineers contributed to various projects for Williams F1, McLaren Automotive, Jaguar Landrover and the new electric London Taxi. And of course we are passionate about eternal classics.
        </p>

        <p>
          In this policy «Charge Automotive», «we», «us» means Charge Automotive Ltd registered at London, England, W14 8TS.
        </p>

        <p>
          We are a «data controller». This means that we are responsible for deciding how we hold and use your personal information. Under data protection laws, we are required to notify you of the information contained in this privacy policy.
        </p>

        <p>
          Regarding privacy concerns and questions, you can reach us directly via email:
          { ' ' }
          <a href="mailto:privacy@abbr.studio">privacy@abbr.studio</a>
        </p>

        <h2>
          What type of information we collect from you
        </h2>

        <p>
          The personal information we collect, store and use might include
        </p>

        <ul>
          <li>Email</li>
          <li>First and last name</li>
          <li>Social network profile (Instagram & Facebook)</li>
          <li>Phone number</li>
          <li>Country</li>
          <li>Bank card information (cardholder name, expiry date, CVV)</li>
        </ul>

        <p>Data protection laws recognise certain categories of personal information as sensitive and therefore requiring greater protection, including but not limited to information about your health, ethnicity and religion. We do not collect sensitive data about you.</p>

        <div
          className={ styles.return }
        >
          <Button
            to="/"
            block="mobile"
          >
            Return to App
          </Button>
        </div>
      </Article>
    </div>
  );
};

export default TermsAndConditions;
