import React, { useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';

import Editable from 'components/Editable';
import Icon from 'components/Icon';
import { Caption } from 'components/Typography';

import validateUrl from 'shared/helpers/functions/validateUrl';

import useAction from '../../shared/helpers/hooks/useAction';

import styles from './UrlField.module.scss';

const variants = {
  initial: {
    rotate: 180,
    scale: 0,
    opacity: 0,
  },
  show: {
    rotate: 0,
    scale: 1,
    opacity: 1,
  },
  exit: {
    rotate: -180,
    scale: 0,
    opacity: 0,
  },
};

const UrlField = ({
  value,
  onChange,
  onValid,
  onError,
}) => {
  const lastValidValue = useRef(value);
  const statusTimeout = useRef(0);
  const [ status, setStatus ] = useState(null);
  const [ url, setUrl ] = useState(value);
  const [ valid, setValid ] = useState(!!value);
  const { trackEvent } = useAction();

  const checkUrlValidity = () => {
    return validateUrl(url)
      .then(() => {
        setValid(true);

        onValid();

        return Promise.resolve(url);
      })
      .catch(() => {
        setValid(false);
        onError();

        return Promise.reject();
      });
  };

  useEffect(() => {
    setUrl(value);
    setValid(true);
  }, [ value ]);

  useEffect(() => {
    checkUrlValidity();
  }, [ url ]);

  useEffect(() => {
    return () => {
      clearTimeout(statusTimeout.current);
    };
  }, []);

  const updateStatus = (newStatus, delay = 2000) => {
    setStatus(newStatus);

    statusTimeout.current = setTimeout(() => {
      setStatus(null);
    }, delay);
  };

  const handleBlur = () => {
    return checkUrlValidity()
      .then((u) => {
        if (lastValidValue.current === u) return;

        onChange({ url: u });
        lastValidValue.current = u;

        trackEvent('ABBR/URL');

        updateStatus('updated');
      })
      .catch(() => {
        setUrl(lastValidValue.current);
        setValid(true);
        updateStatus('error');
      });
  };

  const tooltip = (
    <div className={ styles.tooltip }>
      <Caption className={ styles[valid ? 'valid' : 'invalid'] }>
        <Icon icon={ valid ? 'check' : 'cross' } />
        url is correct
      </Caption>
    </div>
  );

  const adornment = (
    <AnimatePresence exitBeforeEnter>
      {
        status === 'updated' && (
          <motion.div
            className={ styles[status] }
            key="updated"
            variants={ variants }
            initial="initial"
            exit="exit"
            animate="show"
          >
            <Icon
              icon="refresh"
              size="12"
            />
          </motion.div>
        )
      }

      {
        status === 'error' && (
          <motion.div
            className={ styles[status] }
            key="error"
            variants={ variants }
            initial="initial"
            exit="exit"
            animate="show"
          >
            <Icon
              icon="cross"
              size="16"
            />
          </motion.div>
        )
      }
    </AnimatePresence>
  );

  return (
    <Editable
      value={ url }
      placeholder={ lastValidValue.current }
      onChange={ setUrl }
      onBlur={ handleBlur }
      error={ !valid }
      tooltip={ tooltip }
      adornment={ adornment }
      full
    />
  );
};

UrlField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onValid: PropTypes.func,
  onError: PropTypes.func,
};

UrlField.defaultProps = {
  onValid: () => {
  },
  onError: () => {
  },
};

export default UrlField;
