import merge from 'lodash/merge';
import { v1 } from 'uuid';

import * as actionTypes from '../actions/types';

const userState = {
  visitedFirstTime: true,
  token: v1(),
  id: '',
  name: '',
  email: '',
  isLoggedIn: false,
  isTermsAccepted: false,
  events: {},
  notifications: {
    productUpdates: false,
    news: false,
    offers: false,
    service: false,
  },
  subscriptions: {
    features: []
  }
};

const userReducer = (state = userState, action) => {
  switch (action.type) {
    case actionTypes.RECEIVED_USER:
    case actionTypes.GENERATE_TOKEN:
    case actionTypes.REGISTER_FIRST_VISIT:
    case actionTypes.SET_TERMS_ACCEPTED:
    case actionTypes.RECEIVED_EVENTS_FOR_USER:
      return merge({}, state, action.user);
    case actionTypes.LOGIN_USER:
      return merge({}, state, {
        visitedFirstTime: false,
        isLoggedIn: true,
        isTermsAccepted: true,
      });
    case actionTypes.LOGGED_OUT_USER:
      return {
        visitedFirstTime: true,
        id: '',
        name: '',
        email: '',
        subscribed: false,
        isTermsAccepted: false,
        isLoggedIn: false,
      };
    case actionTypes.USER_UNAUTHORIZED:
      return merge({}, state, {
        id: '',
        name: '',
        email: '',
        subscribed: false,
        isLoggedIn: false,
      });
    case actionTypes.SUBSCRIBED_USER:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          ...action.user.subscriptions,
        },
      };
    default:
      return state;
  }
};

export default userReducer;

export const getUser = (state) => state.user;
export const getAnonSession = (state) => state.user.token;
export const getSubscriptionStatus = (type) => (state) => state.user.subscriptions[type];
