import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';

import App from './App';
import ErrorPage from './pages/ErrorPage';
import { SENTRY_DSN } from './shared/constants';

import * as Sentry from '@sentry/browser';

Sentry.init({ dsn: SENTRY_DSN });

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={ ErrorPage }
      onError={ (err) => Sentry.captureException(err) }
    >
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
