import React from 'react';
import { useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';

import Button from 'components/Button';
import Icon from 'components/Icon';
import { useLoader } from 'components/Loader';
import { Heading, Paragraph } from 'components/Typography';

import { getSubscriptionStatus } from 'store/reducers/user';
import useAction from 'shared/helpers/hooks/useAction';

import styles from './Nanolanding.module.scss';

const fade = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

const Nanolanding = () => {
  const subscriptions = useSelector(getSubscriptionStatus('features'));
  const subscribed = subscriptions.includes('nanolanding');

  const {
    subscribeUser,
  } = useAction();
  const [ isLoading, setLoading ] = useLoader();

  const handleSubscription = () => {
    setLoading(true);

    const subscriptionTimeout = setTimeout(() => {
      setLoading(false);

      subscribeUser({
        features: [ ...subscriptions, 'nanolanding' ],
      });
    }, 1000);

    return () => {
      clearTimeout(subscriptionTimeout);
    };
  };

  return (
    <div className={ styles.screen }>
      <div
        className={ styles.message }
      >
        <Heading type="h4">
          <AnimatePresence
            initial={ false }
            exitBeforeEnter
          >
            {
              subscribed
                // eslint-disable-next-line jsx-a11y/accessible-emoji
                ? <motion.span key="1" { ...fade }>Wait for the sign from up above 😏</motion.span>
                : <motion.span key="2" { ...fade }>Nanolandings on the way</motion.span>
            }
          </AnimatePresence>
        </Heading>

        <Paragraph
          marginTop="4"
        >
          <AnimatePresence
            initial={ false }
            exitBeforeEnter
          >
            {
              subscribed
                ? (
                  <motion.span key="3" { ...fade }>
                    You successfully subscribed for the news about nanolandings release
                  </motion.span>
                )
                : <motion.span key="4" { ...fade }>Subscribe to notifications to stay in touch!</motion.span>
            }
          </AnimatePresence>
        </Paragraph>

        <div className={ styles.loader }>
          {
            (isLoading || subscribed) && (
              <Icon
                initialTransition
                icon={ subscribed ? 'check' : 'loading' }
              />
            )
          }
        </div>


        {
          !subscribed && (
            <div
              className={ styles.action }
            >
              <Button
                variant="secondary"
                onClick={ handleSubscription }
                disabled={ isLoading }
                data-subscribe="nanolanding"
                block
              >
                Subscribe
              </Button>
            </div>
          )
        }
      </div>
    </div>
  );
};

Nanolanding.propTypes = {};

export default Nanolanding;
