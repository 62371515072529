import React from 'react';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';
import ProgressRing from 'components/ProgressRing';

import styles from './Achievement.module.scss';

const cn = classnames.bind(styles);

const Achievement = ({
  label,
  icon,
  progress,
  active,
  soon,
  onClick,
  disabled,
}) => {
  const isComplete = progress >= 1;

  const buttonClassName = cn(
    'feature',
    soon && 'soon',
    active && 'active',
    isComplete && 'complete',
  );

  return (
    <button
      type="button"
      className={ buttonClassName }
      onClick={ onClick }
      disabled={ disabled }
    >
      <ProgressRing
        progress={ progress * 100 }
        hideOnComplete
      >
        <Icon icon={ isComplete ? 'check' : icon } />
      </ProgressRing>
      <span className={ styles.label }>{ label }</span>
    </button>
  );
};

Achievement.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  progress: PropTypes.oneOfType([ PropTypes.number, PropTypes.bool ]),
  active: PropTypes.bool,
  soon: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

Achievement.defaultProps = {
  icon: 'cross',
  progress: 0,
  active: false,
  soon: false,
  onClick: () => {
  },
  disabled: false,
};

export default Achievement;
