import React, { useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Icon from 'components/Icon';
import IconButton from 'components/IconButton';
import { useLoader } from 'components/Loader';

const INITIAL_STATE = {
  variant: 'secondary',
  label: 'Share',
  icon: 'share',
};

const stateReducer = (initialState) => (state, action) => {
  switch (action.type) {
    case 'success':
      return {
        variant: 'success',
        label: 'Shared!',
        icon: 'check',
      };
    case 'error':
      return {
        variant: 'danger',
        label: 'Not supported!',
        icon: 'cross',
      };
    case 'cancel':
      return {
        variant: 'warning',
        label: 'Canceled!',
        icon: 'warning',
      };
    default:
      return initialState;
  }
};

const ShareButton = ({
  variant,
  title,
  text,
  url,
  className,
  small,
  fill,
  block,
  onShare,
  ...restProps
}) => {
  const statusTimeout = useRef(0);

  const initialState = {
    ...INITIAL_STATE,
    variant,
  };

  const [ params, dispatch ] = useReducer(stateReducer(initialState), initialState);
  const [ isLoading, setLoading ] = useLoader();

  const updateStatus = (newStatus) => {
    clearTimeout(statusTimeout.current);

    dispatch({ type: newStatus });

    statusTimeout.current = setTimeout(() => {
      dispatch({ type: 'default' });
    }, 2000);
  };

  const handleShare = () => {
    if (navigator.share) {
      setLoading(true);

      navigator.share({
        title,
        text,
        url,
      })
        .then(() => {
          updateStatus('success');
          onShare('native');
        })
        .catch(() => {
          updateStatus('cancel');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      updateStatus('error');
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(statusTimeout.current);
    };
  }, []);

  if (small) {
    return (
      <IconButton
        className={ className }
        variant={ params.variant }
        icon={ params.icon }
        fill={ fill }
        disabled={ isLoading }
        onClick={ handleShare }
        data-abbr="share"
        data-share="native"
        { ...restProps }
      />
    );
  }

  return (
    <Button
      className={ className }
      variant={ params.variant }
      block={ block }
      disabled={ isLoading }
      onClick={ handleShare }
      data-abbr="share"
      data-share="native"
      { ...restProps }
    >
      <Icon icon={ params.icon } />
      { params.label }
    </Button>
  );
};

ShareButton.propTypes = {
  variant: PropTypes.string,
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  small: PropTypes.bool,
  fill: PropTypes.bool,
  block: PropTypes.oneOf([ 'mobile', 'desktop', true, false ]),
  onShare: PropTypes.func,
};

ShareButton.defaultProps = {
  variant: 'secondary',
  title: 'Abbr',
  text: 'Link abbred by Abbr Studio',
  className: '',
  small: false,
  fill: false,
  block: true,
  onShare: () => {
  },
};

export default ShareButton;
