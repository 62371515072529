import React, { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Abbr from 'components/Abbr';
import Button from 'components/Button';
import CopyButton from 'components/CopyButton';
import Divider from 'components/Divider';
import Icon from 'components/Icon';
import Share from 'components/Share';
import { Caption, Heading } from 'components/Typography';

import { getAbbrById } from 'store/reducers/abbr';
import { getUser } from 'store/reducers/user';
import { useMedia } from 'shared/helpers/hocs/withMedia';

import { AUTH_URL } from 'shared/constants';

import IconButton from '../../../../components/IconButton';
import QRcodeModal from '../../../../components/QRcodeModal/QRcodeModal';

import styles from './Complete.module.scss';

const Complete = () => {
  const history = useHistory();
  const { id } = useParams();
  const { isLoggedIn } = useSelector(getUser);
  const { isDesktop } = useMedia();

  const abbr = useSelector(getAbbrById(id));

  useLayoutEffect(() => {
    if (!id) {
      history.push('/create');
    }
  }, [ id ]);

  if (!id) return null;

  return (
    <div className={ styles.page }>
      {
        isDesktop && (
          <Heading
            type="h2"
            className={ styles.heading }
          >
            Upgrade and share
          </Heading>
        )
      }

      <div className={ styles.abbr }>
        <Abbr
          abbrId={ abbr.id }
          showFeatures
        />
      </div>

      <div className={ styles.actions }>
        <div className={ styles.block }>
          <CopyButton
            permalink={ `https://${ abbr.domain }/${ abbr.slashtag }` }
            block
          />
        </div>
        <div className={ styles.col }>
          <Button
            href={ `https://${ abbr.domain }/${ abbr.slashtag }` }
            target="_blank"
            variant="secondary"
            block
          >
            <Icon icon="external" />
            Check
          </Button>
        </div>
        <div className={ styles.col }>
          <Share
            title={ abbr.preview?.title }
            text={ abbr.preview?.description }
            url={ `https://${ abbr.domain }/${ abbr.slashtag }` }
            buttonProps={
              {
                block: true,
              }
            }
          />
        </div>
        <div className={ styles.col }>
          <QRcodeModal permalink={ `https://${ abbr.domain }/${ abbr.slashtag }` }>
            {
              ({ openModal }) => (
                <IconButton
                  icon="qrcode"
                  variant="secondary"
                  onClick={ openModal }
                />
              )
            }
          </QRcodeModal>
        </div>
      </div>

      {
        !isDesktop && (
          <div className={ styles.footer }>
            {
              !isLoggedIn && (
                <Caption marginTop="16">
                  Log in to Abbr Studio to be able to save and edit your abbrs, analyze statistics and add tracking.
                  { ' ' }
                  <a href={ AUTH_URL }>Log In</a>
                </Caption>
              )
            }

            <Divider
              marginTop="16"
              marginBottom="16"
            />

            <div className={ styles.links }>
              <Button
                variant="ghost"
                to="/create"
              >
                <Icon icon="plus-circled" />
                Create new
              </Button>

              <Button
                variant="ghost"
                to="/abbrs"
              >
                Show all abbrs
                <Icon icon="arrow-right" />
              </Button>
            </div>
          </div>
        )
      }
    </div>
  );
}
;

export default Complete;
