import React from 'react';
import PropTypes from 'prop-types';

import styles from './ProgressRing.module.scss';

const ProgressRing = ({
  size,
  progress,
  children,
}) => {
  const diameter = Number(size) + 8;
  const radius = diameter / 2;
  const stroke = Math.ceil(0.03 * diameter);
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - Math.min(progress, 100) / 100 * circumference;

  return (
    <div className={ styles.wrapper }>
      <svg
        className={ styles[progress === 100 ? 'complete' : 'ring'] }
        height={ diameter }
        width={ diameter }
      >
        <circle
          stroke="currentColor"
          fill="transparent"
          strokeDasharray={ `${ circumference } ${ circumference }` }
          style={ { strokeDashoffset } }
          strokeWidth={ stroke }
          r={ normalizedRadius }
          cx={ radius }
          cy={ radius }
        />
      </svg>
      { children }
    </div>
  );
};

ProgressRing.propTypes = {
  size: PropTypes.oneOf([ '16', '20', '24', '32' ]),
  progress: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

ProgressRing.defaultProps = {
  size: '16',
};

export default ProgressRing;
